import {Company} from './company.model';
import {TourSettings} from './tour-settings.model';
import {User} from './user.model';
import {Itinerary} from './itinerary.model';
import {Orders} from './orders.model';
import {Nrooms} from './nrooms.model';
import {TourAccess} from './tour-access.model';
import {TourOptional} from './tour-optional.model';
import {TourPax} from './tour-pax.model';

export class Tour {
  id: number;
  user_id: number;
  company_id: number;
  prodid: string;
  code: string;
  name: string;
  tour_starts: string;
  ndays: number;
  price_pday: number;
  logo: string;
  cover: string;
  npax: number;
  td_overnight: number;
  dietary_issues: string;
  nlated: number;
  ncaxed: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  tour_settings?: TourSettings;
  tour_pax?: TourPax[];
  tour_optionals?: TourOptional[];
  tour_access?: TourAccess[];
  rms?: Nrooms;
  n_convos?: string[];
  n_new_convos?: string[];
  orphan_convos?: any[];
  company: Company;
  user?: User;
  itinerary?: Itinerary[];
  orders?: Orders[];
  state?: any;
  pax_count?: number;
  fakepax_count?: number;
  dayTour?: any;
  show_it?: boolean;
  show_tp?: boolean;
  show_to?: boolean;
  show_or?: boolean;
  show_ta?: boolean;
  deleting?: boolean;

  constructor() {
    this.id = null;
    this.prodid = null;
    this.code = null;
    this.dietary_issues = '';
    this.company = new Company();
    this.rms = new Nrooms();
  }
}
