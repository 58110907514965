import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../../shared/models/user.model';
import {AuthenticationService} from '../../../shared/services/session/authentication.service';
import {OutlookService} from '../../../shared/services/session/outlook.service';
import {HttpTourService} from '../../../shared/services/http/http-tour.service';
import {DatePipe} from '@angular/common';
import {Activetour} from '../../../shared/models/activetour.model';
import {ActivetourService} from '../../../shared/services/session/activetour.service';
import {Tour} from '../../../shared/models/tour.model';
import {SnackbarService} from '../../../shared/services/common/snackbar.service';
import {TitleService} from '../../../shared/services/common/title.service';
import {environment} from '../../../../environments/environment';
import {Contacts} from '../../../shared/models/contacts.model';
import {TextTransformService} from '../../../shared/helpers/texttransform.service';
import {GoogleService} from '../../../shared/services/session/google.service';
import {HttpTemplateService} from '../../../shared/services/http/http-template.service';
import {AppleService} from '../../../shared/services/session/apple.service';
import {EditUserService} from '../../../shared/services/common/edit-user.service';
import {HttpSessionService} from '../../../shared/services/http/http-session.service';
import {VersioningService} from '../../../shared/services/common/versioning.service';
import {TourService} from '../../../shared/services/common/tour.service';
import {Company} from '../../../shared/models/company.model';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe]
})
export class HomeComponent implements OnInit, OnDestroy {
  access: boolean;
  selfUrl: string;
  sessionId: string;
  referrerId: string;
  ef_folder: string;
  companies: Company[] = [];

  va_token: string;
  code: string;
  state: string;
  req_id: number;
  viewAs: number;
  viewAsName: string;
  returnUrl: string;
  currentUser: User;
  progressOn: boolean;
  dev = false;
  loading_msgs: boolean;
  show_archived: boolean;
  beta: boolean;
  tour: Tour;
  toursUser: Tour[];
  current: Tour[];
  comTours: Tour[];
  pasTours: Tour[];
  archTours: Tour[];
  activeTour: Activetour;
  message_list: any[] = [];

  private onDestroy$ = new Subject<boolean>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private titleSvc: TitleService,
              private editUser: EditUserService,
              private versioning: VersioningService,
              private textTransform: TextTransformService,
              private httpTour: HttpTourService,
              private httpSessSvc: HttpSessionService,
              private activeTourSvc: ActivetourService,
              private authSvc: AuthenticationService,
              private outlookSvc: OutlookService,
              private tourSvc: TourService,
              public dialog: MatDialog,
              private templateService: HttpTemplateService,
              private googleService: GoogleService,
              private appleService: AppleService,
              private snackSvc: SnackbarService,
              private datePipe: DatePipe
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
        this.viewAs = this.route.snapshot.queryParams.viewAs || this.currentUser && this.currentUser.view_as || null;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.titleSvc.setTitle('Planafy');
  }

  ngOnInit() {
    // console.log(this.currentUser);
    if (this.currentUser && this.currentUser.role < 4) {
      this.authSvc.logout();
      window.location.href = 'https://app.planafy.com';
    }
    localStorage.removeItem('dayview_role');
    this.companies = JSON.parse(localStorage.getItem('companies') || '[]');
    this.selfUrl = environment.selfUrl;
    this.dev = this.selfUrl.includes('dev');
    this.toursUser = this.activeTourSvc.getToursUser();
    this.progressOn = true;
    this.code = this.route.snapshot.queryParams.code || null;
    this.va_token = this.route.snapshot.queryParams.va_token || null;
    this.state = this.route.snapshot.queryParams.state || null;
    const authuser = this.route.snapshot.queryParams.authuser || null;
    const prompt = this.route.snapshot.queryParams.prompt || null;
    const scope = this.route.snapshot.queryParams.scope || null;
    const user = this.route.snapshot.queryParams.user || null;
    const session_state = this.route.snapshot.queryParams.session_state || null;
    if (this.viewAs && this.currentUser.role >= User.admin) {
      this.currentUser.view_as = this.viewAs;
    } else {
      if (this.route.snapshot.queryParams.viewAs) {
        this.snackSvc.openSnackBar('Not allowed');
      }
    }
    // Remove query params
    this.router.navigate([], {
      queryParams: {
        'viewAs': null,
      },
      queryParamsHandling: 'merge'
    });
    this.authSvc.updateUser(this.currentUser);
    this.req_id = this.viewAs && this.currentUser.view_as ? this.viewAs : this.currentUser?.id;
    this.sessionId = localStorage.getItem('session_id') || null;
    this.referrerId = localStorage.getItem('referrer_id') || null;
    this.access = true;
    console.log(this.code);
    console.log(this.state);
    if (!environment.production) {
      console.log(user);
    }
    // OUTLOOK
    if (this.code && this.state && this.sessionId && session_state) {
      this.onGetTokenOutlook();
      // GOOGLE
    } else if (this.code && this.sessionId && authuser && prompt && scope) {
      this.onGetTokenGoogle();
      // APPLE
    } else if (this.code && this.state && this.sessionId) {
      this.onGetTokenApple(user);
      // ACCESS
    } else {
      this.show_archived = false;
      if (!this.currentUser) {
        console.log('No currentUser');
        this.progressOn = false;
        this.access = false;
        this.snackSvc.openSnackBar('No current user');
        this.authSvc.logout();
        this.router.navigate(['login', 'reset']);
        return;
      }
      // VERIFY TOUR ACCESS
      if (this.va_token) {
        this.verifyTourAccess();
      }
      this.getTourUsers();
      if (this.currentUser && this.currentUser.email !== 'beta@planafy.com') {
        this.ef_folder = this.currentUser.user_settings.ef_folder_id || null;
        this.getUserContactsList();
      }
      if (!this.ef_folder && this.currentUser.email !== 'beta@planafy.com') {
        this.createEFfolders();
      }
      if (this.currentUser && this.currentUser.template) {
        this.currentUser.template = TextTransformService.replaceBRs2NL(this.currentUser.template);
      }
    }
  }

  printIt() {
    console.log(this.activeTour);
    console.log(this.currentUser);
    this.outlookGetMails();
  }

  closeInfo() {
    this.currentUser.first_time = false;
    this.authSvc.updateUser(this.currentUser);
  }

  /*
  /* HTTP REQUESTS
  *******************/

  // ACCESS OUTLOOK
  onGetTokenOutlook() {
    const data = {
      'code': this.code,
      'state': this.state,
      'session_id': this.sessionId,
      'environment_url': window.location.origin,
    };
    // console.log(data);
    this.outlookSvc.outlookGetToken(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          // console.log(res);
          if (res.status === 200) {
            this.onAccessOutlook();
          } else {
            // console.log('Error accessing outlook. Please try again');
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
            }
            this.progressOn = false;
            this.access = false;
            this.authSvc.logout();
            this.router.navigate(['login', 'reset']);
          }
        },
        error => {
          console.log(error);
        });
  }

  onAccessOutlook() {
    const data = {
      'session_id': this.sessionId,
      'referrer_id': this.referrerId,
    };
    // console.log(data);
    this.outlookSvc.registerOutlook(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          // console.log(res);
          if (res.status === 200) {
            localStorage.removeItem('referrer_id');
            console.log(res.message.toString());
            this.ef_folder = this.currentUser.user_settings.ef_folder_id;
            if (!this.ef_folder) {
              this.createEFfolders();
            }
            this.returnUrl = localStorage.getItem('returnUrl') !== 'undefined' ? JSON.parse(localStorage.getItem('returnUrl')) : null;
            if (this.returnUrl) {
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigate(['tours']);
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
            }
            this.progressOn = false;
            this.access = false;
            this.authSvc.logout();
            this.router.navigate(['login', 'reset']);
          }
        },
        error => {
          console.log(error);
          this.authSvc.logout();
          this.router.navigate(['login']);
        });
  }

  // ACCESS GOOGLE
  onGetTokenGoogle() {
    const data = {
      'code': this.code,
      'session_id': this.sessionId,
      'environment_url': window.location.origin,
    };
    // console.log(data);
    this.googleService.googleGetToken(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          // console.log(res);
          if (res.status === 200) {
            this.onAccessGoogle();
          } else {
            // console.log('Error accessing outlook. Please try again');
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  onAccessGoogle() {
    const data = {
      'session_id': this.sessionId,
      'referrer_id': this.referrerId,
    };
    this.googleService.registerGoogle(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          // console.log(res);
          if (res.status === 200) {
            console.log(res.message.toString());
            this.returnUrl = localStorage.getItem('returnUrl') !== 'undefined' ? JSON.parse(localStorage.getItem('returnUrl')) : null;
            if (this.returnUrl) {
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigate(['home']);
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.authSvc.logout();
          this.router.navigate(['login']);
        });
  }

  // ACCESS APPLE
  onGetTokenApple(user) {
    const data = {
      'code': this.code,
      'state': this.state,
      'session_id': this.sessionId,
      'user': user,
      'environment_url': window.location.origin,
    };
    this.appleService.appleGetToken(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          // console.log(res);
          if (res.status === 200) {
            this.onAccessApple(res.results);
          } else {
            console.log('Error accessing outlook. Please try again');
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  onAccessApple(user) {
    const data = {
      'session_id': this.sessionId,
      'user_id': user.id,
      'first_time': !!user['first_time'],
      'merged': !!user['merged'],
      'referrer_id': this.referrerId,
    };
    this.appleService.registerApple(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          // console.log(res);
          if (res.status === 200) {
            console.log(res.message.toString());
            this.returnUrl = localStorage.getItem('returnUrl') !== 'undefined' ? JSON.parse(localStorage.getItem('returnUrl')) : null;
            if (this.returnUrl) {
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigate(['home']);
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.authSvc.logout();
          this.router.navigate(['login']);
        });
  }

  // REQUESTS
  // Verify tour access
  async verifyTourAccess() {
    const res = await this.httpSessSvc.verifyUserAccess(this.va_token);
    console.log(res);
    this.snackSvc.openSnackBar(res.message);
    this.router.navigate(['tours']);
  }

  // Create EF folder if does not exist
  async createEFfolders() {
    const data = {
      'user_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'destination': 'inbox',
    };
    if (!environment.production) {
      data['test'] = true;
    }
    // console.log(data);
    const res = await this.outlookSvc.createEFFolder(data);
    console.log(res);
    if (res.results) {
      this.ef_folder = res.results.ef_folder_id;
      this.currentUser.user_settings.ef_folder_id = this.ef_folder;
      this.authSvc.updateUser(this.currentUser);
    }
  }

  // Get Users contact list
  async getUserContactsList() {
    const force = this.route.snapshot.queryParams.contacts;
    if (this.currentUser.contacts && !force) {
      const upd = new Date(this.currentUser.contacts.updated_at);
      upd.setDate(upd.getDate() + 1);
      if (this.currentUser.contacts.updated_at && (upd >= new Date())) {
        console.log('Contacts list newer than 24h: ', new Date(this.currentUser.contacts.updated_at));
        return;
      }
    }
    const data = {
      session_id: localStorage.getItem('session_id'),
      user_id: this.currentUser.id
    };
    const res = await this.outlookSvc.getContacts(data);
    console.log(res);
    if (res.status === 200) {
      this.currentUser.contacts = new Contacts(res.results);
      this.currentUser.contacts.updated_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es-ES');
      this.currentUser.contacts.count = this.currentUser.contacts.list.length;
      this.authSvc.updateUser(this.currentUser);
    } else {
      if (res.message.includes('Error getting token')) {
        // Show dialog to fix problem
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            autoFocus: true,
            disabled: false,
            confirmTitle: 'Attention',
            confirmAction: 'Log out',
            confirmMessage: 'Expired credentials. Please, log out and back in'
          }
        });
        dialogRef.afterClosed().subscribe(reason => {
          if (reason) {
            this.authSvc.logout();
            this.tourSvc.getOutlookURL();
          }
        });
      } else {
        this.snackSvc.resultsElse(res);
      }
    }
  }

  // GET ALL INFORMATION FROM THE USERS TOURS
  getTourUsers() {
    this.progressOn = true;
    this.httpTour.getToursUser(this.req_id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            localStorage.removeItem('folder_id');
            this.versioning.check(res.results.version);
            this.toursUser = res.results.tours;
            if (this.toursUser.length < 1) {
              this.activeTour = new Activetour();
            }
            this.currentUser.user_settings = res.results.user_settings;
            this.currentUser.template = TextTransformService.replaceBRs2NL(res.results.template);
            this.authSvc.updateUser(this.currentUser);
            this.currentUser.role = res.results.user_role;
            localStorage.setItem('cities', JSON.stringify(res.results.cities));
            localStorage.setItem('compTypes', JSON.stringify(res.results.comp_types));
            localStorage.setItem('questTypes', JSON.stringify(res.results.quest_types));
            localStorage.setItem('gssTypes', JSON.stringify(res.results.gss_types));
            localStorage.setItem('companies', JSON.stringify(res.results.companies));
            this.activeTourSvc.setToursUser(this.toursUser);
            if (this.viewAs) {
              this.viewAsName = this.authSvc.viewerAs(this.viewAs);
            } else {
              this.authSvc.updateUser(this.currentUser);
            }
            this.organizeToursUser();
            this.access = false;
            this.outlookGetMails();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.progressOn = false;
        },
        error => {
          this.progressOn = false;
          this.snackSvc.openSnackBar(error);
          console.log(error);
        });
  }

  onRemoveView() {
    this.currentUser.view_as = null;
    this.authSvc.updateUser(this.currentUser);
    this.toursUser = [];
    this.organizeToursUser();
    this.viewAs = null;
    this.viewAsName = null;
    this.req_id = this.currentUser.id;
    this.getTourUsers();
  }

  onHideTours() {
    this.currentUser.user_settings.past_tours = false;
    this.editUser.onUserSettsChange('past_tours');
  }

  /*
  /* TOUR MANAGEMENT
  *******************/

  onClickTour(tour) {
    this.tour = this.toursUser.find(obj => '' + obj.id === '' + tour.id);
    this.activeTour = new Activetour();
    if (this.tour) {
      this.activeTour.tour = this.tour;
    }
    this.activeTourSvc.setActiveTour(this.activeTour);
    if (!tour.prodid) {
      this.snackSvc.openSnackBar('Error. Prodid required');
      this.router.navigate(['tourinfo', this.activeTour.tour.id]);
    } else if (!tour.npax) {
      this.snackSvc.openSnackBar('Error. PAX info required');
      this.router.navigate(['pax', this.activeTour.tour.prodid], {queryParams: {returnUrl: '/tour/' + tour.prodid}});
    } else {
      this.activeTour.tour.id = tour.id;
      this.activeTour.tour.prodid = tour.prodid;
      const tour_messages = this.message_list.find(item => '' + item.tour === '' + this.activeTour.tour.prodid);
      if (tour_messages) {
        delete tour_messages.tour;
        this.activeTour.message_list = tour_messages;
      }
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', tour.prodid]);
    }
  }

  // Delete given tour
  onDeleteTour(tour) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete tour?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          this.httpTour.deleteTour(tour.id, this.currentUser.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status === 204) {
                  const tIdx = this.toursUser.findIndex(it => '' + it.prodid === '' + tour.prodid);
                  if (tIdx >= 0) {
                    this.toursUser.splice(tIdx, 1);
                  }
                  this.activeTourSvc.setToursUser(this.toursUser);
                  this.organizeToursUser();
                  this.tourSvc.onDeleteTourFolder();
                }
              },
              error => {
                console.log(error);
              });
        }
      });
  }

  organizeToursUser() {
    const today = this.textTransform.addDays(new Date(), 0);
    this.current = this.toursUser
      .filter(d => !d.tour_settings.archived && (today >= this.textTransform.addDays(new Date(d.tour_starts), 0) &&
        today < this.textTransform.addDays(new Date(d.tour_starts), d.ndays)));
    this.comTours = this.toursUser.filter(d => !d.tour_settings.archived && (!d.tour_starts ||
      (today < this.textTransform.addDays(new Date(d.tour_starts), 0))));
    this.pasTours = this.toursUser.filter(d => !d.tour_settings.archived && (d.tour_starts &&
      (today >= this.textTransform.addDays(new Date(d.tour_starts), d.ndays))));
    this.archTours = this.toursUser.filter(d => d.tour_settings.archived);
    // Sort em all
    this.current = this.sortArrayFields(this.current, ['tour_starts']);
    this.comTours = this.sortArrayFields(this.comTours, ['tour_starts']);
    this.pasTours = this.sortArrayFields(this.pasTours, ['-tour_starts']);
    this.archTours = this.sortArrayFields(this.archTours, ['-tour_starts']);
  }

  onNewTour() {
    this.activeTour = new Activetour();
    this.router.navigate(['new']);
  }

  onEditTour(tour) {
    if (tour.prodid) {
      const activeTour = new Activetour();
      activeTour.tour = tour;
      this.activeTourSvc.setActiveTour(activeTour);
      this.router.navigate(['tour', tour.prodid, 'settings'], {queryParams: {returnUrl: window.location.pathname}});
    } else {
      this.onClickTour(tour);
    }
  }

  onPayTour(tour) {
    tour.tour_settings.archived = 1;
    const activeTour = new Activetour();
    activeTour.tour = tour;
    this.activeTourSvc.setActiveTour(activeTour);
    this.router.navigate(['summary', tour.prodid], {queryParams: {returnUrl: window.location.pathname}});
  }

  onArchiveTour(tour) {
    tour.tour_settings.archived = 1;
    this.updateTour(tour, [{'key': 'archived', 'value': 1}]);
  }

  onUnarchiveTour(tour) {
    tour.tour_settings.archived = 0;
    this.updateTour(tour, [{'key': 'archived', 'value': 0}]);
  }

  updateTour(tour, values: any) {
    const params = {
      'req_id': this.currentUser.id,
    };
    for (const i of values) {
      params[i.key] = '' + i.value;
    }
    this.httpTour.updateTour(tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status === 203) {
          tour = res.results.tour;
          this.organizeToursUser();
        } else {
          this.snackSvc.resultsElse(res);
        }
      }, error => {
        console.log(error);
        this.snackSvc.openSnackBar('ERROR. Archiving tour');
      });
  }

  /*
  /* MESSAGES MANAGEMENT
  *******************/

  async outlookGetMails() {
    if (!this.currentUser.outlook_id) {
      console.log('No outlook user');
      return;
    }
    this.loading_msgs = true;
    const content = {
      'user_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
    };
    const res = await this.outlookSvc.getUserMailsAsync(content);
    console.log(res);
    if (res.status < 400) {
      // Entry point returns 200 with null if no recent tours to fetch mails from
      this.message_list = res.results.messages;
      if (this.activeTour?.tour?.id) {
        this.activeTour.message_list = this.message_list.find(ml => '' + ml.tour === '' + this.activeTour.tour.prodid);
        this.activeTourSvc.setActiveTour(this.activeTour);
      }
      for (const msg of this.message_list) {
        const tourIdx = this.toursUser.findIndex(item => '' + item.prodid === '' + msg.tour);
        if (tourIdx >= 0) {
          this.toursUser[tourIdx].n_convos = msg.n_convos;
          this.toursUser[tourIdx].n_new_convos = msg.n_new_convos;
        }
      }
      this.organizeToursUser();
    }
    this.loading_msgs = false;
  }

  // Sort itinerary fields
  sortArrayFields(arr, field) {
    const fieldSorter = (fields) => (a, b) => fields.map(o => {
      let dir = 1;
      if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
      }
      return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0);
    arr.sort(fieldSorter(field));
    return arr;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
