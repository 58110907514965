import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Activetour} from '../../../../../../shared/models/activetour.model';
import {User} from '../../../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../../../../shared/services/session/authentication.service';
import {ActivetourService} from '../../../../../../shared/services/session/activetour.service';
import {takeUntil} from 'rxjs/operators';
import {SnackbarService} from '../../../../../../shared/services/common/snackbar.service';
import {Router} from '@angular/router';
import {FlagService} from '../../../../../../shared/services/common/flag.service';
import {HttpTourService} from '../../../../../../shared/services/http/http-tour.service';
import {NewtourService} from '../../services/newtour.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, OnDestroy {
  // Active tour info
  activeTour: Activetour;
  currentUser: User;

  file: any;

  loading = false;
  loadingPDF = false;
  replaceable = true;
  updatable = true;
  msg_txt = 'Tour already exists!';

  @Input() public from: string;
  @Input() public already_exists: boolean;
  @Output() remFile = new EventEmitter();
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private activeTourSvc: ActivetourService,
    private router: Router,
    private snackSvc: SnackbarService,
    private flagService: FlagService,
    private httpTourSvc: HttpTourService,
    private newTourSvc: NewtourService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
  }

  ngOnInit(): void {
  }

  setManually() {
    this.newTourSvc.setManually(true);
  }

  updateNewTour() {
    this.uploadTDI('update');
  }

  replaceNewTour() {
    this.uploadTDI('replace');
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onFileDropped($event) {
    this.file = null;
    this.prepareFilesList($event);
  }

  fileBrowseHandler(file) {
    this.file = null;
    this.prepareFilesList(file);
  }

  prepareFilesList(file) {
    if (this.loadingPDF) {
      return;
    }
    if (!file[0].type.includes('pdf')) {
      this.snackSvc.openSnackBar('File must be a pdf');
      return;
    }
    this.file = file[0];
    this.uploadTDI();
  }

  removeFile() {
    this.file = null;
    this.activeTour.tour.name = null;
    this.activeTour.tour.prodid = null;
    this.activeTour.tour.code = null;
    this.activeTourSvc.setActiveTour(this.activeTour);
    this.remFile.emit();
  }

  uploadTDI(dir?) {
    this.loadingPDF = true;
    this.msg_txt = 'Tour already exists!';
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('user_id', '' + this.currentUser.id);
    formData.append('company_id', '' + this.activeTour.tour.company_id);
    if (dir) {
      formData.append('directive', dir);
    }
    // console.log('formData', formData);
    this.httpTourSvc.uploadTDI(formData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          if (res.results) {
            this.activeTour.tour = res.results.tour;
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.activeTourSvc.addTour2User(this.activeTour.tour);
            this.newTourSvc.setRes(res);
          }
          if (res.status === 201) {
            if (this.from === 'NT') {
              this.file = null;
              this.router.navigate(['summary', this.activeTour.tour.prodid]);
            } else {
              this.msg_txt = res.message;
              if (res.message.includes('replaced')) {
                this.replaceable = false;
                this.updatable = false;
                this.already_exists = true;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate(['tour', this.activeTour.tour.prodid]);
                });
              } else if (res.message.includes('created')) {
                this.replaceable = false;
                this.updatable = false;
                this.already_exists = true;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate(['summary', this.activeTour.tour.prodid]);
                });
              }
            }
          } else if (res.status === 204) {
            this.already_exists = true;
            this.replaceable = true;
            this.updatable = true;
          } else if (res.status === 205) {
            this.already_exists = true;
            this.replaceable = false;
            this.updatable = false;
            this.msg_txt = res.message;
          } else {
            this.snackSvc.openSnackBar(res.message);
          }
        } else {
          this.already_exists = true;
          this.replaceable = false;
          this.updatable = false;
          let error;
          if (res.results) {
            error = res.results[Object.keys(res.results)[0]].toString();
          } else {
            error = res.message.toString();
          }
          this.msg_txt = error;
        }
        this.loadingPDF = false;
      }, error => {
        this.loadingPDF = false;
        console.log(error);
        this.snackSvc.openSnackBar('Error. Try again');
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error uploadTDI()', JSON.stringify(error));
      });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
