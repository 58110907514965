import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ExifOptions} from '@digitalascetic/ngx-pica/lib/ngx-pica-resize-options.interface';
import {NgxPicaErrorInterface, NgxPicaImageService, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {HttpComponentService} from '../../../../shared/services/http/http-component.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {User} from '../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {Components} from '../../../../shared/models/components.model';
import {Comptype} from '../../../../shared/models/comptype.model';
import {City} from '../../../../shared/models/city.model';
import {Hotel} from '../../../../shared/models/hotel.model';
import {SelectionModel} from '@angular/cdk/collections';
import {MatListOption} from '@angular/material/list';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpItineraryService} from '../../../../shared/services/http/http-itinerary.service';
import {DatePipe} from '@angular/common';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {environment} from '../../../../../environments/environment';
import {Day} from '../../../../shared/models/day.model';

@Component({
  selector: 'app-adm-comps',
  templateUrl: './adm-comps.component.html',
  styleUrls: ['./adm-comps.component.scss']
})
export class AdmCompsComponent implements OnInit, OnDestroy {
  currentUser: User;
  component: Components = null;
  res_comps: Components[] = [];
  compTypes: Comptype[];
  cities: City[];
  hotelsCity: Hotel[] = [];

  baseUrlLogo: string;
  compId: string;
  searchText: string;
  loading = false;
  hotel: boolean;
  safety: boolean;
  on_hotel: boolean;
  guss: boolean;
  headsets: boolean;
  transfer: boolean;
  gussbus: boolean;
  restaurant: boolean;
  optional: boolean;
  activity: boolean;
  freetime: boolean;
  tl_private: boolean;
  incident: boolean;
  other: boolean;
  d_nightsRequests: string;
  edit: string;
  idxEvts: number[] = [];
  idxDays: number[] = [];

  dayId: number;
  itinDays: Day[] = [];
  showDays = false;

  private onDestroy$ = new Subject<boolean>();
  @ViewChild('delcomps') delComps: SelectionModel<MatListOption>;

  constructor(
    private httpComp: HttpComponentService,
    private httpItin: HttpItineraryService,
    private ngxPicaService: NgxPicaService,
    private datePipe: DatePipe,
    private activeTourSvc: ActivetourService,
    private httpTour: HttpTourService,
    private ngxPicaImageService: NgxPicaImageService,
    private snackSvc: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private authSvc: AuthenticationService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Escape') {
      this.edit = null;
      this.idxEvts = [];
      this.idxDays = [];
    }
  }

  ngOnInit(): void {
    this.baseUrlLogo = environment.baseUrl;
    this.compTypes = JSON.parse(localStorage.getItem('compTypes') || '[]');
    this.cities = JSON.parse(localStorage.getItem('cities') || '[]');
    this.compId = this.route.snapshot.queryParams['c'] || null;
    if (this.compId) {
      this.searchText = this.compId;
      this.getComponentInfo(this.compId);
    }
  }

  onDelSearch() {
    this.component = null;
    this.searchText = null;
    this.res_comps = null;
    this.router.navigate([], {
      queryParams: {'c': null},
      queryParamsHandling: 'merge'
    });
  }

  searchComponent(txt) {
    if (!txt) {
      this.snackSvc.openSnackBar('Insert search text');
      return;
    }
    this.loading = true;
    this.component = null;
    this.res_comps = null;
    const data = {
      'query': txt
    };
    // console.log(data);
    this.httpComp.searchComponent(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.res_comps = res.results.components;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Searching component');
        });
  }

  getComponentInfo(id) {
    if (!id) {
      this.snackSvc.openSnackBar('Insert component');
      return;
    }
    this.loading = true;
    this.res_comps = null;
    this.searchText = id;
    this.httpComp.getComponent(id, this.currentUser.id, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.component = res.results.component;
            this.itinDays = res.results.component.days;
            if (this.component.mailable === undefined || this.component.mailable === null) {
              this.component.mailable = res.results.component.comp_type?.mailable;
            }
            // GENERAL STUFF
            // Change url
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {c: id},
              queryParamsHandling: 'merge'
            });
            // Show end time
            this.hotelsCity = res.results.hotels;
          } else if (res.status === 400) {
            this.component = null;
            this.snackSvc.openSnackBar(res.message.toString());
          } else {
            this.component = null;
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.component = null;
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Getting component');
        });
  }

  onSelectionTypeChange(event, forced?: boolean) {
    // Avoid early trigger
    if (!this.component) {
      return;
    }
    // console.log(event);
    this.component.showenddate = this.component.start_date !== this.component.end_date;
    switch (event.value) {
      case 1:
        // console.log('Hotel');
        this.setAllFalse();
        this.hotel = true;
        this.component.showenddate = true;
        if (forced) {
          this.component.city_id = null;
          this.component.itinerary.hotel_id = null;
        }
        // this.getDriverNights();
        break;
      case 2:
        // console.log('Guided Tour');
        this.setAllFalse();
        this.guss = true;
        break;
      case 3:
        // console.log('Headsets');
        this.setAllFalse();
        this.headsets = true;
        break;
      case 4:
        // console.log('Bus for Guided Tour');
        this.setAllFalse();
        this.transfer = true;
        this.gussbus = true;
        break;
      case 5:
        // console.log('Restaurant');
        this.setAllFalse();
        this.restaurant = true;
        break;
      case 6:
        // console.log('Activity');
        this.setAllFalse();
        this.activity = true;
        break;
      case 7:
        // console.log('Free Time');
        this.setAllFalse();
        this.freetime = true;
        this.component.showenddate = true;
        break;
      case 8:
        // console.log('Tour Optional');
        this.setAllFalse();
        this.optional = true;
        break;
      case 9:
        // console.log('TL Optional');
        this.setAllFalse();
        this.optional = true;
        break;
      case 10:
        // console.log('Orientation Walk');
        this.setAllFalse();
        this.optional = true;
        break;
      case 11:
        // console.log('Taxi Transfer');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 12:
        // console.log('Bus Transfer');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 13:
        // console.log('Flight');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 14:
        // console.log('Boat');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 15:
        // console.log('Train');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 16:
        // console.log('Arrivals');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 17:
        // console.log('Departures');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 18:
        // console.log('Info');
        this.setAllFalse();
        this.other = true;
        // this.component.mailable = false;
        break;
      case 19:
        // console.log('TL Private');
        this.setAllFalse();
        this.tl_private = true;
        break;
      case 20:
        // console.log('Incident');
        this.setAllFalse();
        this.incident = true;
        break;
      case 21:
        // console.log('Special');
        this.setAllFalse();
        this.other = true;
        if (this.component.name === 'Pre-tour COVID test') {
          this.incident = true;
        }
        break;
      case 22:
        // console.log('Boat Overnight');
        this.setAllFalse();
        this.hotel = true;
        this.on_hotel = true;
        this.component.showenddate = true;
        // this.getDriverNights();
        break;
      case 23:
        // console.log('Safety Check');
        this.setAllFalse();
        this.safety = true;
        break;
      default:
        this.setAllFalse();
        this.other = true;
        break;
    }
    const idx = this.compTypes.findIndex(it => '' + it.type === '' + event.value);
    if (idx >= 0) {
      this.component.comp_type = this.compTypes[idx];
      if (event.source) {
        this.component.mailable = this.component.comp_type.mailable;
      }
    }
  }

  setAllFalse() {
    this.hotel = false;
    this.on_hotel = false;
    this.restaurant = false;
    this.guss = false;
    this.transfer = false;
    this.gussbus = false;
    this.freetime = false;
    this.optional = false;
    this.other = false;
    this.headsets = false;
    this.tl_private = false;
    this.safety = false;
    this.incident = false;
  }

  uploadComponentImage(comp_id, event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      this.loading = true;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 400, 300, options)
            .subscribe((imageResized: File) => {
              let newFile: File;
              if (file.type.includes('gif')) {
                newFile = new File([imageCompressed], file.name, {type: imageResized.type});
              } else {
                newFile = new File([imageResized], file.name, {type: imageResized.type});
              }
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('component_id', '' + comp_id);
              this.httpComp.sendComponentImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    if (res.status < 400) {
                      this.component.image = res.results.component.image;
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                    this.loading = false;
                  },
                  error => {
                    this.loading = false;
                    console.log(error);
                    this.snackSvc.openSnackBar('ERROR. Updating component image');
                  });

            }, (err: NgxPicaErrorInterface) => {
              this.loading = false;
              console.log('image not resized');
              console.log(err.err);
            });

        }, (err: NgxPicaErrorInterface) => {
          this.loading = false;
          console.log('image not compressed');
          console.log(err.err);
        });
      elem.value = '';
    }
  }

  deleteImage() {
    this.component.image = null;
    this.updateComponent('del_image');
  }

  updateComponent(type?: string) {
    const val = this.validateInput();
    if (val) {
      this.snackSvc.openSnackBar(val);
      return;
    }
    // Remove last comma if present
    if (this.component.recipients) {
      this.component.recipients = this.component.recipients.replace(/,\s*$/, '').replace(/\s/g, '');
    }
    let data = {};
    if (!type) {
      data = {
        'comp_type_id': this.component.comp_type_id,
        'name': this.component.name,
        'itinerary_id': this.component.itinerary_id,
        'start_date': this.component.start_date,
        'end_date': this.component.end_date,
        'city_id': this.component.city_id,
        'npax': this.component.npax,
        'from': this.component.from,
        'to': this.component.to,
        'image': this.component.image,
        'supplier': this.component.supplier,
        'hotel_extras': this.d_nightsRequests,
        'state': this.component.state,
        'requests': this.component.requests,
        'subject': this.component.subject,
        'recipients': this.component.recipients,
        'description': this.component.description,
        'mailable': this.component.mailable,
        'message_id': this.component.message_id,
        'sent_at': this.component.sent_at,
        'conversation_id': this.component.conversation_id,
        'confirm_notes': this.component.confirm_notes,
        'confirmed_at': this.component.confirmed_at,
      };
    } else if (type && type === 'restore') {
      data['restore'] = true;
    } else if (type && type === 'del_image') {
      data['image'] = null;
      data['del_image'] = true;
    }
    // console.log(data);
    this.httpComp.updateComponent(this.component.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 203) {
            this.component = {
              ...res.results.component,
              days: [...(this.component?.days || [])]
            };
          } else {
            console.log('Error: component not updated');
            this.snackSvc.resultsElse(res);
          }
          this.edit = null;
        },
        error => {
          this.edit = null;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating component');
        });
  }

  updateItin(comp) {
    const data = {
      'city_id': comp.itinerary.city_id,
      'hotel_id': comp.itinerary.hotel_id,
      'hotel_name': comp.itinerary.hotel_name
    };
    // console.log(data);
    this.httpItin.updateItineraryTour(comp.itinerary.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            comp.itinerary.hotel = res.results.itinerary.hotel;
          } else {
            console.log('Error: itinerary not updated');
            this.snackSvc.resultsElse(res);
          }
          this.edit = null;
        },
        error => {
          this.edit = null;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating itinerary');
        });
  }

  validURL(str) {
    const pattern = new RegExp(
      '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})', 'i');
    return !str || !!pattern.test(str);
  }

  checkUrl(type?: string): boolean {
    if (!this.validURL(this.component.events[0]['url'])) {
      if (type !== 'silent') {
        this.snackSvc.openSnackBar('ERROR. Not a valid URL');
      }
      return false;
    }
    if (this.component.events[0]['url'] && this.component.events[0]['url'].includes('youtube.com')) {
      this.component.events[0]['url'] = this.component.events[0]['url'].replace('http://', '');
      if (!this.component.events[0]['url'].includes('https://', 0)) {
        this.component.events[0]['url'] = 'https://' + this.component.events[0]['url'];
      }
    }
    if (this.component.events[0]['url'] && this.component.events[0]['url'].includes('youtu.be/')) {
      const videoId = this.component.events[0]['url'].split('.be/')[1];
      this.component.events[0]['url'] = 'https://youtube.com/watch?v=' + videoId;
    }
    return true;
  }

  onEditDay(day: Day) {
    const idx = this.idxDays.findIndex(e => '' + e === '' + day.id);
    const data = {
      'req_id': this.currentUser.id,
      'name': day.name,
      'date': day.date,
      'nday': day.nday,
      'sunrise': day.sunrise,
      'sunset': day.sunset,
    };
    // console.log(data);
    this.httpItin.updateDay(day.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            day = res.results.day;
          } else {
            this.snackSvc.resultsElse(res);
          }
          if (idx >= 0) {
            this.idxDays.splice(idx, 1);
          }
        },
        error => {
          console.log(error);
          if (idx >= 0) {
            this.idxDays.splice(idx, 1);
          }
          this.snackSvc.openSnackBar('Error editing day name');
        });
  }

  createEvent(day) {
    const data = {
      'component_id': this.component.id,
      'day_id': day.id,
      'visible': false,
      'show_times': false,
    };
    this.loading = true;
    if (this.component.comp_type_id === 1) {
      if (this.component.events.length < 1) {
        data['name'] = 'Check-in ' + this.component.supplier;
      } else {
        data['name'] = 'Breakfast in ' + this.component.supplier;
      }
    } else if (this.component.comp_type_id === 7) {
      data['name'] = 'Free time ' + this.component.city.name;
    } else {
      data['name'] = this.component.name;
    }
    // console.log(data);
    this.httpItin.createEvent(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.showDays = false;
            this.component.events.push(res.results.event);
            this.component.end_date = res.results.event.day.date;
            this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
            this.updateComponent('end_date');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error creating event');
          this.loading = false;
        });
  }

  updateEvent(event) {
    const idx = this.idxEvts.findIndex(e => '' + e === '' + event.id);
    const data = {
      'req_id': this.currentUser.id,
      'day_id': event.day_id,
      'start_time': event.start_time,
      'end_time': event.end_time,
      'meet_time': event.meet_time,
      'meet_place': event.meet_place,
      'name': event.name,
      'private_notes': event.private_notes,
      'public_notes': event.public_notes,
      'url': this.checkUrl('silent') ? event.url : null,
      'visible': event.visible,
      'show_times': event.show_times,
    };
    // console.log(data);
    this.httpItin.updateEvent(event.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          event = res.results.event;
          const itemIndex = this.component.events.findIndex(ev => '' + ev.id === '' + event.id);
          this.component.events[itemIndex] = event;
        } else {
          this.snackSvc.resultsElse(res);
        }
        if (idx >= 0) {
          this.idxEvts.splice(idx, 1);
        }
      }, error => {
        console.log(error);
        if (idx >= 0) {
          this.idxEvts.splice(idx, 1);
        }
        this.snackSvc.openSnackBar('ERROR. Updating event');
      });
  }

  deleteEvent(event) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Force delete event?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.httpItin.deleteEvent(this.currentUser.id, event.id, 'true')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
              console.log(res);
              if (res.status < 400) {
                const evIdx = this.component.events.findIndex(ev => ev.id === event.id);
                this.component.events.splice(evIdx, 1);
              } else {
                this.snackSvc.resultsElse(res);
              }
            }, error => {
              console.log(error);
              this.snackSvc.openSnackBar('ERROR. Deleting event');
            });
        }
      });
  }

  toggleStatus(comp: Components) {
    const old_state = comp.state;
    const old_conf = comp.confirmed_at;
    if (old_state !== 'PEND' && !old_conf) {
      comp.state = null;
      comp.confirmed_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'UTC');
    } else if (old_conf) {
      comp.state = 'PEND';
      comp.confirmed_at = null;
    } else {
      comp.state = null;
      comp.confirmed_at = null;
    }
    const data = {
      'confirmed_at': comp.confirmed_at,
      'state': comp.state,
    };
    // console.log(data);
    this.httpComp.updateComponent(comp.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // ok
          } else {
            comp.state = old_state;
            comp.confirmed_at = old_conf;
            this.snackSvc.openSnackBar('ERROR. Updating confirmation');
          }
        },
        error => {
          comp.state = old_state;
          comp.confirmed_at = old_conf;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating confirmation');
        });
  }

  deleteComponent() {
    this.httpComp.deleteComponent(this.component.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 204) {
            this.component.deleted_at = 'deleted';
            // If dismiss or no action delete the component + event from memory
          } else {
            // Error deleting city component in DB but we remove it anyway from memory
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Deleting component');
        });
  }

  onForceDeleteComponent() {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Force delete component?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.httpComp.deleteComponent(this.component.id, this.currentUser.id, 'true')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status === 204) {
                  this.component = null;
                  // If dismiss or no action delete the component + event from memory
                } else {
                  // Error deleting city component in DB but we remove it anyway from memory
                  this.snackSvc.resultsElse(res);
                }
              },
              error => {
                console.log(error);
                this.snackSvc.openSnackBar('ERROR. Force deleting component');
              });
        }
      });
  }

  validateInput() {
    if (!this.component.comp_type_id) {
      return 'Error. Component type needed';
    }
    if (this.component.city_id <= 0) {
      return 'Error. City needed';
    }
    if (!this.component.start_date) {
      return 'Error. Start date needed';
    }
    if (this.component.end_date && (this.component.end_date < this.component.start_date)) {
      return 'Error. Invalid dates';
    }
    if (!this.component.end_date) {
      this.component.end_date = this.component.start_date;
    }
    if (!this.component.from && (this.transfer && this.component.mailable)) {
      return 'Error. "From" field needed';
    }
    if (!this.component.to && (this.transfer && this.component.mailable)) {
      return 'Error. "To" field needed';
    }
    if (this.component.recipients) {
      const list = this.component.recipients.split(',');
      let valid = true;
      for (let email of list) {
        // Allow ",   " after first email
        email = email.trim();
        if (email) {
          valid = valid && this.validateEmail(email);
        }
      }
      if (!valid) {
        return 'Error. Recipients not valid [5]';
      }
    }
  }

  validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onViewAs(user) {
    this.httpTour.getToursUser(user.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            localStorage.setItem('cities', JSON.stringify(res.results.cities));
            localStorage.setItem('compTypes', JSON.stringify(res.results.comp_types));
            localStorage.setItem('gssTypes', JSON.stringify(res.results.gss_types));
            localStorage.setItem('companies', JSON.stringify(res.results.companies));
            this.activeTourSvc.setToursUser(res.results.tours);
            this.currentUser.view_as = user.id;
            this.authSvc.updateUser(this.currentUser);
            this.router.navigate(['tours'], {queryParams: {viewAs: user.id}});
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          this.snackSvc.openSnackBar('ERROR View as');
          console.log(error);
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
