import {Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import timezones from '../../../../../assets/json/tz.json';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {User} from '../../../../shared/models/user.model';
import {Components} from '../../../../shared/models/components.model';
import {HttpComponentService} from '../../../../shared/services/http/http-component.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {Activetour} from '../../../../shared/models/activetour.model';
import {Comptype} from '../../../../shared/models/comptype.model';
import {Event} from '../../../../shared/models/event.model';
import {DatePipe} from '@angular/common';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {OutlookService} from '../../../../shared/services/session/outlook.service';
import {HttpItineraryService} from '../../../../shared/services/http/http-itinerary.service';
import {TitleService} from '../../../../shared/services/common/title.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Message} from '../../../../shared/models/message.model';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {environment} from '../../../../../environments/environment';
import {NgxPicaErrorInterface, NgxPicaImageService, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {ExifOptions} from '@digitalascetic/ngx-pica/lib/ngx-pica-resize-options.interface';
import {Hotel} from '../../../../shared/models/hotel.model';
import {AddHotelComponent} from '../../../components/hotel/add-hotel/add-hotel.component';
import {MatDialog} from '@angular/material/dialog';
import {HttpHotelService} from '../../../../shared/services/http/http-hotel.service';
import {Contact} from '../../../../shared/models/contact.model';
import {CopyComponentComponent} from '../../../components/tour/copy-component/copy-component.component';
import {City} from '../../../../shared/models/city.model';
import {Contacts} from '../../../../shared/models/contacts.model';
import {CopyFromComponent} from '../../../components/tour/copy-from/copy-from.component';
import * as Editor from '../../../../../../ckeditor5/build/ckeditor';
import {HttpTourOptionalService} from '../../../../shared/services/http/http-tour-optional.service';
import {Optional} from '../../../../shared/models/optional.model';
import {HttpFormService} from '../../../../shared/services/http/http-form.service';
import {ScheduleSendComponent} from '../../../components/tour/schedule-send/schedule-send.component';
import {BreakpointObserver} from '@angular/cdk/layout';
import {VersioningService} from '../../../../shared/services/common/versioning.service';
import {TourService} from '../../../../shared/services/common/tour.service';
import {CreateOptionalComponent} from '../../../components/tour/create-optional/create-optional.component';

@Component({
  selector: 'app-view-component',
  templateUrl: './view-component.component.html',
  styleUrls: ['./view-component.component.scss']
})
export class ViewComponentComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  public Editor = Editor;
  public EditorPN = Editor;

  loading: boolean;
  loadingHotels: boolean;
  baseUrl: string;
  selfUrl: string;
  apiUrl: string;
  folder_id: string;
  city_slug: string;
  refresh = false;
  sub = false;
  edit = false;
  edFocus = false;
  htmlEdited = false;
  show_form = false;
  timezones: any;
  optionParam: string;
  day_view: boolean;

  component: Components;
  same_type: Components[] = [];
  compTypes: Comptype[];
  cities: City[];
  cities_tour: City[] = [];
  comIdx: number;
  day_id: number;
  dayIdx: number;
  itIdx: number;
  nextDayIdx: number;
  returnUrl: string;

  hotelsCity: Hotel[] = [];
  suggOpts: Optional[] = [];

  messages: Message[] = [];
  reply_id: string;
  reply_msg: string;
  reply_attachs: any[] = [];

  beta: boolean;
  tourPro: boolean;
  isSafari: boolean;
  isMobile: boolean;
  show_reply_box = false;
  newComponent = false;
  showFRL = false;
  lastItin = false;
  show_act_checkin = false;
  admin = false;
  dietaryIssues: string;
  placeholder: string;
  find_att = '\n\nPlease find attached the Final Rooming List.';

  // VARS
  subject: string;
  msg_loading = false;
  att_loading = false;
  conf_load = false;
  changedType = false;
  showPax = false;
  showTimezones = false;
  showCC = false;
  showBCC = false;
  showRCC = false;
  overNight: boolean;
  driverSleeps = true;
  d_nightsRequests: string;
  Dnights: any[] = [];
  showFromAddress: boolean;
  showToAddress: boolean;
  hotel: boolean;
  safety: boolean;
  on_hotel: boolean;
  guss: boolean;
  headsets: boolean;
  transfer: boolean;
  arrivals: boolean;
  departures: boolean;
  gussbus: boolean;
  restaurant: boolean;
  optional: boolean;
  td_optional: boolean;
  activity: boolean;
  orient_walk: boolean;
  info: boolean;
  freetime: boolean;
  tl_private: boolean;
  incident: boolean;
  birthday: boolean;
  other: boolean;
  numberPax: number;
  numberStaff: number;
  showDiet: boolean;
  showPublicNotes: boolean;
  showPrivateNotes: boolean;
  showHExtra: boolean;
  editBasicComp = false;
  sc_hr: string;
  sc_min: string;
  event_day_notes = 0;

  recipients: string[] = [];
  cc_recipients: string[] = [];
  bcc_recipients: string[] = [];
  reply_recipients: string[] = [];
  reply_recs_cc: string[] = [];
  reply_scheduled_send: string;
  control = new FormControl('');
  filteredTimezones: Observable<string[]>;

  @ViewChild('compName') compNameElementSubjectRef: ElementRef;
  @ViewChild('compFrom') fromElementSubjectRef: ElementRef;
  @ViewChild('compTo') toElementSubjectRef: ElementRef;
  @ViewChild('subject') searchElementSubjectRef: ElementRef;
  @ViewChild('draft') searchElementDraftRef: ElementRef;
  @ViewChild('body') searchElementBodyRef: ElementRef;
  @ViewChild('public_notes') searchElementPublicNotesRef: ElementRef;
  @ViewChild('reply_box') searchElementReplyBoxRef: ElementRef;
  @ViewChildren('target') searchElementMsgsRefs: QueryList<ElementRef>;
  @ViewChild('attachInput1') myInput1Variable: ElementRef;
  @ViewChild('attachInput2') myInput2Variable: ElementRef;

  private onDestroy$ = new Subject<boolean>();

  private static _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  @HostListener('window:keyup', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.editBasicComp = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown($event): void {
    const charCode = String.fromCharCode($event.which).toLowerCase();
    if (($event.ctrlKey || $event.metaKey) && charCode === 's') {
      this.createMsgUpdComp('just_save');
      $event.preventDefault();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private snackSvc: SnackbarService,
    private versioning: VersioningService,
    private httpForm: HttpFormService,
    private httpCompSvc: HttpComponentService,
    private httpItin: HttpItineraryService,
    private httpHotSvc: HttpHotelService,
    private httpTour: HttpTourService,
    private httpTOPSvc: HttpTourOptionalService,
    private outlookSvc: OutlookService,
    private titleSvc: TitleService,
    private textTransform: TextTransformService,
    private flgSvc: FlagService,
    private actTourSvc: ActivetourService,
    private datePipe: DatePipe,
    private tourSvc: TourService,
    private sanitizer: DomSanitizer,
    private ngxPicaService: NgxPicaService,
    private ngxPicaImageService: NgxPicaImageService,
    private breakpointObserver: BreakpointObserver,
    private authSvc: AuthenticationService
  ) {
    this.timezones = Object.keys(timezones);
    this.actTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.tourPro = !!tour?.tour.tour_settings.paid_at;
        this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
      });
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
        this.beta = this.currentUser.email === 'beta@planafy.com';
        this.admin = this.currentUser.role > 9;
        if (!this.currentUser.contacts || this.currentUser.contacts?.list.length === 0) {
          this.currentUser.contacts = new Contacts();
          // this.currentUser.contacts.list = [{id: null, name: 'Contacts list empty! Fill in Outlook to see here', email: ''}];
          this.authSvc.updateUser(this.currentUser);
        }
      });
  }

  ngOnInit() {
    this.isSafari = /apple/i.test(navigator.vendor);
    this.isMobile = this.authSvc.isMobileDevice();
    this.apiUrl = environment.apiUrl;
    this.component = new Components();
    this.compTypes = JSON.parse(localStorage.getItem('compTypes') || '[]');
    this.cities = JSON.parse(localStorage.getItem('cities') || '[]');
    this.day_view = JSON.parse(localStorage.getItem('day_view'));
    this.suggOpts = [];
    this.numberStaff = 1;
    this.baseUrl = environment.baseUrl;
    this.selfUrl = environment.selfUrl;
    this.show_reply_box = false;
    this.optionParam = this.route.snapshot.queryParams['opt'] || null;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    this.reply_scheduled_send = null;
    // Filtered Timezones
    this.filteredTimezones = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.refresh = this.route.snapshot.queryParams['ref'];
    this.sub = this.route.snapshot.queryParams['sub'] === 'true';
    this.edit = this.route.snapshot.queryParams['edit'] === 'true';
    if (this.route.snapshot.queryParams['edit']) {
      const queryParams = {...this.route.snapshot.queryParams};
      delete queryParams['edit'];
      const navigationExtras: NavigationExtras = {
        queryParams: queryParams,
        replaceUrl: true
      };
      this.router.navigate([], navigationExtras);
    }
    // SECURITY CHECKS
    // If user has no tours or user's tours none is the prodid
    const toursUser = this.actTourSvc.getToursUser();
    const tour_prodid = this.route.snapshot.params['tour-prodid'];
    const tour = toursUser.find(x => '' + x.prodid === '' + tour_prodid);
    if (!toursUser || !tour) {
      this.snackSvc.openSnackBar('Error. Invalid tour');
      this.router.navigate(['tours']);
      return;
    }
    if (!this.activeTour) {
      this.activeTour = new Activetour();
      this.httpTour.getTourAll(tour.id, this.currentUser.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            this.activeTour.tour = res.results.tour;
            this.activeTour.days = res.results.days;
            this.activeTour.itinerary = res.results.itinerary;
            this.activeTour.components = res.results.components;
            this.activeTour.optionals = res.results.optionals;
            this.activeTour.events = res.results.events;
            this.activeTour.orders = res.results.orders;
            this.activeTour.tour_pax = res.results.tour_pax;
            this.processTour();
          },
          error => {
            console.log(error);
            this.loading = false;
            this.snackSvc.openSnackBar('Error getting tours');
          });
    } else {
      // Check if tour is the active one
      if (this.activeTour.tour.prodid !== tour_prodid) {
        this.snackSvc.openSnackBar('Error. Not active tour');
        this.router.navigate(['tours']);
        return;
      }
      // If day not in active tour
      this.day_id = this.route.snapshot.params['day_id'];
      this.dayIdx = this.activeTour.days.findIndex(x => '' + x.id === '' + this.day_id);
      if (this.dayIdx < 0) {
        this.snackSvc.openSnackBar('Error. Invalid day');
        this.router.navigate(['tour', tour.prodid]);
        return;
      }
      // If itinerary not found
      this.itIdx = this.activeTour.itinerary.findIndex(x => '' + x.id === '' + this.activeTour.days[this.dayIdx].itinerary_id);
      if (this.itIdx < 0) {
        this.snackSvc.openSnackBar('Error. Invalid itinerary');
        this.router.navigate(['tour', tour.prodid]);
        return;
      }
      this.processTour();
    }
  }

  processTour() {
    this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
    this.numberPax = this.activeTour.tour.npax;
    this.dietaryIssues = this.activeTour.tour.dietary_issues;
    this.cities_tour = this.cities.filter(c => {
      if (this.activeTour.itinerary.map((i) => i.city_id).includes(c.id)) {
        return c;
      }
    });
    // If component not new
    if (this.route.snapshot.params['component_id'] !== 'new') {
      const comp_id = this.route.snapshot.params['component_id'];
      this.getComponentInfo(comp_id);
    } else {
      // NEW COMPONENT
      this.newComponent = true;
      this.loading = true;
      // Prefill all information possible
      this.component.comp_type_id = +this.route.snapshot.queryParams['type_id'] || null;
      const arrORdep = this.route.snapshot.queryParams['arrdep'] || null;
      const name = this.route.snapshot.queryParams['name'] || null;
      const email = this.route.snapshot.queryParams['email'] || null;
      const supplier = this.route.snapshot.queryParams['supplier'] || null;
      const city_id = this.route.snapshot.queryParams['city_id'] || null;
      this.component.events[0].day_id = this.activeTour.days[this.dayIdx].id;
      this.component.events[0].component_id = this.component.id;
      this.component.start_date = this.activeTour.days[this.dayIdx].date;
      this.component.start_day = this.datePipe.transform(this.component.start_date, 'EEEE');
      this.component.end_date = this.component.start_date;
      this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
      this.component.itinerary_id = this.activeTour.itinerary[this.itIdx].id;
      this.component.city_id = city_id ? +city_id : +this.activeTour.itinerary[this.itIdx].city_id;
      this.component.city_name = this.cities.find(c => +c.id === +this.component.city_id).name;
      this.city_slug = this.textTransform.urlize(this.component.city_name);
      // If buses
      if (this.component.comp_type_id === 16) {
        this.component.from = this.component.city_name + ' airport';
        this.component.to = this.activeTour.itinerary[this.itIdx].hotel.name;
        this.component.name = 'Arrivals bus';
      } else if (this.component.comp_type_id === 17) {
        this.component.to = this.component.city_name + ' airport';
        this.component.from = this.activeTour.itinerary[this.itIdx].hotel.name;
        this.component.name = 'Departures bus';
        // taxis
      } else if (this.component.comp_type_id === 11) {
        if (arrORdep === 'arrivals') {
          this.component.name = 'Arrivals taxi';
          this.component.from = this.component.city_name + ' airport';
          this.component.to = this.activeTour.itinerary[this.itIdx].hotel.name;
        } else {
          this.component.name = 'Departures taxi';
          this.component.to = this.component.city_name + ' airport';
          this.component.from = this.activeTour.itinerary[this.itIdx].hotel.name;
        }
        // TD Optionals
      } else if (this.component.comp_type_id === 9) {
        this.component.name = name;
        this.component.supplier = supplier;
        this.component.recipients = email;
        this.component.events[0].public_notes = 'TD OPTIONAL (Extra Cost)<br>Sign up on \'More info\'<br>';
        // Restaurant
      } else if (this.component.comp_type_id === 5) {
        this.component.name = name;
        this.component.supplier = supplier;
        this.component.recipients = email;
        this.component.requests = '{di}';
        // ELSE
      } else {
        this.component.name = name;
        this.component.supplier = supplier;
        this.component.recipients = email;
      }
      this.createNewComponent(this.component.name === 'Arrivals Check-list');
    }

  }

  printInfo() {
    if (this.admin) {
      console.log(this.currentUser);
      console.log(this.activeTour);
      console.log(this.component);
      console.log('messages', this.messages);
      if (this.component.message_id && this.component.message_id?.substring(0, 3) !== 'pln') {
        this.outlookGetConvos();
      }
    }
  }

  // Force get Users contact list
  async forceUserContactsList(verbose?) {
    this.currentUser.contacts.list = [{id: null, name: 'Updating contacts list...', email: ''}];
    const data = {
      session_id: localStorage.getItem('session_id'),
      user_id: this.currentUser.id
    };
    const res = await this.outlookSvc.getContacts(data);
    console.log(res);
    if (res.status < 400) {
      this.currentUser.contacts = new Contacts(res.results);
      this.currentUser.contacts.updated_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es-ES');
      this.currentUser.contacts.count = this.currentUser.contacts.list.length;
      this.authSvc.updateUser(this.currentUser);
      if (verbose) {
        this.snackSvc.openSnackBar('Contacts updated');
      }
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  goBack() {
    if (this.day_view === false) {
      this.router.navigate(['../../../../'], {relativeTo: this.route});
      return;
    }
    if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(['../../'], {relativeTo: this.route});
    }
  }

  public onFocusEditor() {
    this.edFocus = true;
  }

  public onChangeEditor() {
    // this.edFocus = true;
  }

  public onFocusPNEditor() {
    this.edFocus = true;
  }

  public onChangePNEditor() {
    if (this.component.events[this.event_day_notes].public_notes?.length > 2048) {
      this.snackSvc.openSnackBar('Exceeded limit of 2048');
    }
  }

  strip_html_tags(str) {
    if ((str === null) || (str === '')) {
      return '';
    } else {
      str = str.toString();
      return str.replace(/<[^>]*>/g, '');
    }
  }

  private _filter(value: string): string[] {
    const filterValue = ViewComponentComponent._normalizeValue(value);
    return this.timezones.filter(tz => ViewComponentComponent._normalizeValue(tz).includes(filterValue));
  }

  selectTz(event) {
    this.component.to = event.option.value;
    this.updateComponent('to');
    this.showTimezones = false;
    this.control.reset();
  }

  getComponentInfo(id) {
    this.loading = true;
    this.edFocus = false;
    this.newComponent = false;
    this.httpCompSvc.getComponent(id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.versioning.check(res.results.version);
            // Error if component does not belong to tour
            if ('' + res.results.component.itinerary.tour_id !== '' + this.activeTour.tour.id) {
              this.snackSvc.openSnackBar('Error. Invalid component');
              this.flgSvc.setFlag(this.currentUser.id, this.router.url,
                'Invalid component - comp id:' + id + '; tour id:' + this.activeTour.tour.id,
                'Component not in tour');
              this.router.navigate(['../../'], {relativeTo: this.route});
              return;
            }
            this.component = res.results.component;
            // If hotel: check pax
            if (this.component.comp_type_id === 1 || this.component.comp_type_id === 22) {
              if (this.tourSvc.checkPax()) {
                this.snackSvc.openSnackBar('Fix PAX rooms');
                if (this.currentUser.role < User.admin) {
                  this.router.navigate(['pax', this.activeTour.tour.prodid], {queryParams: {returnUrl: window.location.pathname}});
                  return;
                }
              }
            }
            this.same_type = res.results.same_type;
            this.same_type.map(st => st.day_id = '' + this.activeTour.days.find(d => d.date === st.start_date).id);
            this.comIdx = this.activeTour.components.findIndex(it => +it.id === +this.component.id);
            // Next Day index
            this.nextDayIdx = this.activeTour.days.findIndex(d => d.nday === this.component.events.slice(-1)[0].day.nday + 1);
            if (this.component.mailable === undefined || this.component.mailable === null) {
              this.component.mailable = res.results.component.comp_type?.mailable;
            }
            // Transfer PAX
            this.numberPax = this.component.npax ? +this.component.npax.split(' + ')[0] : +this.activeTour.tour.npax;
            this.numberStaff = this.component.npax ? +this.component.npax.split(' + ')[1] : 1;
            // Logic
            this.showCC = !!this.component.recs_cc;
            this.showBCC = !!this.component.recs_bcc;
            // Refresh template
            if (this.refresh) {
              this.refresh = false;
              if (this.sub && this.component.requests && this.component.requests.includes('{sub}')) {
                this.component.requests = this.component.requests.replace('{sub}', '');
              }
              this.createMsgUpdComp();
            }
            this.editBasicComp = !!this.edit;
            this.edit = false;
            this.onSelectionTypeChange({index: this.component.comp_type_id - 1, value: this.component.comp_type_id});
            // GENERAL STUFF
            // Show end time
            this.component.events.map(it => {
              it.showendtime = it.end_time !== null;
              // Warn about color styling
              if (it.public_notes?.includes('style="color:')) {
                it.warning_txt = 'Warning: applied color may break app\'s dark mode';
              }
            });
            // Recipients list
            if (this.component.recipients) {
              this.component.recipients = this.component.recipients.replace(/,/g, ', ');
              this.recipients = this.component.recipients.split(',');
            } else {
              this.recipients = [];
            }
            // CC list
            if (this.component.recs_cc) {
              this.component.recs_cc = this.component.recs_cc.replace(/,/g, ', ');
              this.cc_recipients = this.component.recs_cc.split(',');
            } else {
              this.cc_recipients = [];
            }
            // BCC list
            if (this.component.recs_bcc) {
              this.component.recs_bcc = this.component.recs_bcc.replace(/,/g, ', ');
              this.bcc_recipients = this.component.recs_bcc.split(',');
            } else {
              this.bcc_recipients = [];
            }
            // save in memory
            this.activeTour.components[this.comIdx] = this.component;
            this.actTourSvc.setActiveTour(this.activeTour);
            this.city_slug = this.textTransform.urlize(this.component.city.name);
            // last itin
            const lastItinIdx = this.activeTour.itinerary.findIndex(it => it.check_in === this.component.itinerary.check_out);
            if (lastItinIdx < 0) {
              this.lastItin = true;
              this.component.requests = this.component.requests?.replace('{ha_to}', '');
            }
            // hotel addresses
            this.showFromAddress = this.component.requests?.includes('{ha_from}');
            this.showToAddress = this.component.requests?.includes('{ha_to}');
            // If conversation id, fetch message + attachments
            if (this.component.conversation_id && !this.beta) {
              this.outlookGetConvos();
            }
            // Possible hotels
            res.results.hotels.map(h => {
              if (!h.confirmed) {
                h.name = 'Select hotel';
              }
            });
            this.hotelsCity = res.results.hotels;
            this.suggOpts = res.results.optionals;
            // Check if edited html
            this.htmlEdited = this.component.html_body && !this.component.html_body?.includes('<div id=') || false;
            // Display extras
            const idx = this.component.events.findIndex(ev => ev.public_notes || ev.meet_place || ev.url);
            this.event_day_notes = this.event_day_notes ? this.event_day_notes : (idx >= 0 ? idx : 0);
            this.showPublicNotes = idx >= 0 || this.currentUser.id === 334; // Georgios Zach
            this.showPrivateNotes = !!this.component.confirm_notes;
            this.show_act_checkin = !!this.component.events[0].meet_time;
            this.showHExtra = !!this.component.hotel_extras || !!this.show_act_checkin;
            this.showDiet = this.component.requests?.includes('{di}');
            const reply_drafts = JSON.parse(localStorage.getItem('reply_drafts') || '[]');
            if (reply_drafts) {
              this.reply_msg = reply_drafts.find(t => t.id === this.component.id)?.msg;
              this.reply_id = reply_drafts.find(t => t.id === this.component.id)?.reply_id;
              if (this.component.recipients && !this.reply_recipients.includes(this.component.recipients)) {
                this.reply_recipients = this.component.recipients.split(',');
              }
              this.show_reply_box = !!this.reply_msg;
            }
            // days
            this.component.start_day = this.datePipe.transform(this.component.start_date, 'EEEE');
            this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
            // Check params
            if (this.optionParam) {
              switch (this.optionParam) {
                case 'conf':
                  this.toggleConfirm();
                  break;
                case 'conf0':
                  this.component.confirmed_at = null;
                  this.updateComponent('confirmed_at');
                  break;
                case 'conf1':
                  this.component.confirmed_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'UTC');
                  this.updateComponent('confirmed_at');
                  break;
                default:
                  break;
              }
            }
            if (!localStorage.getItem('folder_id')) {
              this.tourSvc.onCreateTourFolder(false);
            }
            if (this.beta) {
              const div = document.createElement('div');
              div.innerHTML = this.component.html_body;
              const regex = new RegExp('<style([\\s\\S]+?)</style>', 'gm');
              let bodyPreview = div.innerHTML.replace(regex, '');
              const regex1 = new RegExp('(?:<|&lt;).*?(?:>|&gt;)', 'gm');
              bodyPreview = bodyPreview.replace(regex1, '');
              bodyPreview = this.strip_html_tags(bodyPreview);
              bodyPreview = bodyPreview.substring(0, 85);
              const msg = new Message();
              msg.body = {'contentType': 'html', 'content': this.component.html_body};
              msg.bodyPreview = bodyPreview;
              msg.conversationId = this.component.conversation_id;
              msg.conversationIndex = '';
              msg.createdDateTime = this.component.sent_at;
              msg.from = [{'emailAddress': {'name': this.currentUser.name, 'address': this.currentUser.email}}];
              msg.hasAttachments = '';
              msg.id = this.component.message_id;
              msg.isDraft = false;
              msg.isRead = true;
              msg.replyTo = [{'emailAddress': {'name': this.currentUser.name, 'address': this.currentUser.email}}];
              msg.sender = {'emailAddress': {'name': this.currentUser.name, 'address': this.currentUser.email}};
              msg.sentDateTime = this.component.sent_at;
              msg.subject = this.component.subject;
              msg.toRecipients = [{'emailAddress': {'name': this.component.recipients, 'address': this.component.recipients}}];
              msg.uniqueBody = '';
              msg.webLink = this.component.web_link;
              msg.folded = true;
              msg.showDeets = false;
              this.messages.push(msg);
            }
          } else if (res.status === 400) {
            this.snackSvc.openSnackBar(res.message.toString());
            this.router.navigate(['../../'], {relativeTo: this.route});
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Getting component');
        });
  }

  onSelectionDateChange(event) {
    // To avoid early trigger of Select change
    if (event.isUserInput && event.source.value !== this.component.start_date || event.force) {
      // Update the new day in memory
      const dayIdx = this.activeTour.days.findIndex(it => it.date === event.source.value);
      if (dayIdx >= 0 && this.component.events.length > 0) {
        for (let i = 0; i < this.component.events.length; i++) {
          const ev = this.component.events[i];
          ev.day = this.activeTour.days[dayIdx + i];
          if (this.activeTour.days[dayIdx + i]) {
            ev.day_id = this.activeTour.days[dayIdx + i].id;
          } else {
            this.snackSvc.openSnackBar('Error. Not enough days');
            return;
          }
        }
      }
      const days_diff = this.textTransform.dateDiffInDays(new Date(this.component.end_date), new Date(this.component.start_date));
      this.component.start_date = event.source.value;
      this.component.start_day = this.datePipe.transform(this.component.start_date, 'EEEE');
      this.component.end_date = this.textTransform.addDays(new Date(this.component.start_date + 'T11:59:59.000Z'), days_diff);
      this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
      this.component.showenddate = this.component.start_date !== this.component.end_date;
      // Avoid trigger when new (and unknown)
      if (!this.newComponent) {
        this.updateComponent('just_save');
      }
    }
  }

  onSelectionCityChange(event) {
    if (event.index < 0) {
      return;
    }
    const old_name = this.component.city_name;
    // Update the new city in memory
    this.component.city_id = Number(event.value);
    this.hotelsCity = [];
    this.getCityHotels(this.component.city_id);
    const idx = this.activeTour.itinerary.findIndex(it => '' + it.city_id === '' + event.value);
    if (idx >= 0) {
      this.component.itinerary = this.activeTour.itinerary[idx];
      this.component.itinerary_id = this.activeTour.itinerary[idx].id;
      const new_name = this.activeTour.itinerary[idx].city.name;
      this.city_slug = this.textTransform.urlize(new_name);
      if (this.component.name && this.component.name.includes(old_name)) {
        this.component.name = this.component.name.replace(old_name, new_name);
        this.component.events.map(e => {
          if (e.name) {
            e.name = e.name.replace(old_name, new_name);
          }
        });
      }
      this.component.city = this.cities.find(city => +city.id === +this.component.city_id);
      this.component.city_name = new_name;
      if (this.hotel) {
        this.component.supplier = null;
        this.component.name = 'Hotel in ' + new_name;
      }
      if (this.freetime) {
        this.component.name = 'Free time ' + new_name;
        this.component.image = 'storage/city/' + new_name.toLowerCase() + '/' + new_name.toLowerCase().substring(0, 3) + '_cover';
      }
      if (this.guss || this.headsets || this.gussbus || this.component.comp_type_id === 17) {
        this.component.from = this.activeTour.itinerary[idx].hotel_name;
      }
      if (this.headsets || this.component.comp_type_id === 16) {
        this.component.to = this.activeTour.itinerary[idx].hotel_name;
      }
      // Orientation walk comp type 10
      if (this.component.comp_type_id === 10) {
        this.component.supplier = this.component.city_name;
      }
    } else {
      // ADD A FREETIME CITY
      if (!this.activeTour.tour.tour_settings.paid_at) {
        this.snackSvc.openSnackBar('Please, purchase before adding a city');
        return;
      }
      if (!this.component.city_id) {
        this.snackSvc.openSnackBar('Please, select a city');
        return;
      }
      const data = {
        'tour_id': '' + this.activeTour.tour.id,
        'city_id': this.component.city_id,
        'check_in': this.textTransform.addDays(new Date(this.activeTour.itinerary[this.activeTour.itinerary.length - 1].check_out), 14),
        'check_out': this.textTransform.addDays(new Date(this.activeTour.itinerary[this.activeTour.itinerary.length - 1].check_out), 15),
        'travel_by': '0',
      };
      // console.log(data);
      this.httpItin.addItineraryTour(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              this.activeTour.itinerary.push(res.results.itin);
              // Possible hotels
              res.results.hotels.map(h => {
                if (!h.confirmed) {
                  h.name = 'Select hotel';
                }
              });
              this.hotelsCity = res.results.hotels;
              this.component.itinerary = res.results.itin;
              this.component.itinerary_id = res.results.itin.id;
              this.component.city_name = this.cities.find(c => c.id === this.component.city_id).name;
              const new_name = this.component.city_name;
              if (this.component.comp_type_id === 7) {
                this.component.name = 'Free time ' + new_name;
                this.component.image = 'storage/city/' + new_name.toLowerCase() + '/' + new_name.toLowerCase().substring(0, 3) + '_cover';
              }
              this.activeTour.components[this.comIdx] = this.component;
              this.actTourSvc.setActiveTour(this.activeTour);
            } else {
              console.log('Error: itinerary not created');
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
            this.snackSvc.openSnackBar('ERROR. Creating itinerary');
          });
    }
  }

  onSelectionHotelChange(event) {
    if (event.index < 0 || event.value < 0) {
      return;
    }
    const itidx = this.activeTour.itinerary.findIndex(it => '' + it.id === '' + this.component.itinerary_id);
    const ho_idx = this.hotelsCity.findIndex(ho => '' + ho.id === '' + event.value);
    this.activeTour.itinerary[itidx].hotel = this.hotelsCity[ho_idx];
    this.activeTour.itinerary[itidx].hotel_name = this.hotelsCity[ho_idx].name;
    this.component.itinerary.hotel = this.hotelsCity[ho_idx];
    this.component.supplier = this.hotelsCity[ho_idx].name;
    this.component.image = this.hotelsCity[ho_idx].image;
    this.component.events.map(e => e.url = this.hotelsCity[ho_idx].url);
    this.activeTour.components[this.comIdx] = this.component;
    this.actTourSvc.setActiveTour(this.activeTour);
    const idx = this.component.events.findIndex(ev => ev.public_notes || ev.meet_place || ev.url);
    this.event_day_notes = this.event_day_notes ? this.event_day_notes : (idx >= 0 ? idx : 0);
    this.showPublicNotes = idx >= 0 || this.currentUser.id === 334; // Georgios Zach
    this.updateItinerary(itidx);
  }

  onSelectionTypeChange(event, forced?: boolean) {
    // Avoid early trigger
    if (!this.component) {
      return;
    }
    this.component.showenddate = this.component.start_date !== this.component.end_date;
    this.showPax = false;
    this.changedType = true;
    this.placeholder = 'Youtube, Google/MS Forms, Dropbox, ...';
    switch (event.value) {
      case 1:
        // console.log('Hotel');
        this.setAllFalse();
        this.hotel = true;
        this.showFRL = true;
        this.placeholder = 'https://goo.gl/maps/xxx...';
        this.overNight = this.activeTour.tour.td_overnight === 1;
        this.component.showenddate = true;
        if (forced) {
          this.component.city_id = null;
          this.activeTour.itinerary[this.itIdx].hotel_id = null;
          this.component.itinerary.hotel_id = null;
        }
        this.getDriverNights();
        break;
      case 2:
        // console.log('Guided Tour');
        this.setAllFalse();
        this.guss = true;
        if (forced) {
          this.component.from = this.activeTour.itinerary[this.itIdx].hotel_name;
          this.component.to = 'Guided Sightseeing';
        }
        break;
      case 3:
        // console.log('Headsets');
        this.setAllFalse();
        if (forced) {
          this.component.from = this.activeTour.itinerary[this.itIdx].hotel_name;
          this.component.to = this.activeTour.itinerary[this.itIdx].hotel_name;
        }
        this.headsets = true;
        break;
      case 4:
        // console.log('Bus for Guided Tour');
        this.setAllFalse();
        if (forced) {
          this.component.from = this.activeTour.itinerary[this.itIdx].hotel_name;
          this.component.to = 'Guided Sightseeing';
        }
        this.transfer = true;
        this.gussbus = true;
        break;
      case 5:
        // console.log('Restaurant');
        this.setAllFalse();
        this.restaurant = true;
        break;
      case 6:
        // console.log('Activity');
        this.setAllFalse();
        this.activity = true;
        break;
      case 7:
        // console.log('Free Time');
        this.setAllFalse();
        this.freetime = true;
        this.component.showenddate = true;
        this.city_slug = this.textTransform.urlize(this.component.city.name);
        break;
      case 8:
        // console.log('Tour Optional');
        this.setAllFalse();
        this.optional = true;
        break;
      case 9:
        // console.log('TD Optional');
        this.setAllFalse();
        this.td_optional = true;
        this.placeholder = 'Youtube, Dropbox, ...';
        break;
      case 10:
        // console.log('Orientation Walk');
        this.setAllFalse();
        this.orient_walk = true;
        break;
      case 11:
        // console.log('Taxi Transfer');
        this.setAllFalse();
        this.transfer = true;
        this.showPax = true;
        break;
      case 12:
        // console.log('Bus Transfer');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 13:
        // console.log('Flight');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 14:
        // console.log('Boat');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 15:
        // console.log('Train');
        this.setAllFalse();
        this.transfer = true;
        break;
      case 16:
        // console.log('Arrivals');
        this.setAllFalse();
        if (forced) {
          this.component.from = this.activeTour.itinerary[this.itIdx].city.name + ' airport';
          this.component.to = this.activeTour.itinerary[this.itIdx].hotel_name;
        }
        this.transfer = true;
        this.arrivals = true;
        this.showPax = true;
        break;
      case 17:
        // console.log('Departures');
        this.setAllFalse();
        if (forced) {
          this.component.from = this.activeTour.itinerary[this.itIdx].hotel_name;
          this.component.to = this.activeTour.itinerary[this.itIdx].city.name + ' airport';
        }
        this.transfer = true;
        this.departures = true;
        this.showPax = true;
        break;
      case 18:
        // console.log('Info');
        this.setAllFalse();
        this.info = true;
        // this.component.mailable = false;
        break;
      case 19:
        // console.log('TL Private');
        this.setAllFalse();
        this.tl_private = true;
        break;
      case 20:
        // console.log('Incident');
        this.setAllFalse();
        this.incident = true;
        break;
      case 21:
        // console.log('Special');
        this.setAllFalse();
        this.other = true;
        break;
      case 22:
        // console.log('Boat Overnight');
        this.setAllFalse();
        this.hotel = true;
        this.on_hotel = true;
        this.showFRL = true;
        this.placeholder = 'https://goo.gl/maps/xxx...';
        this.overNight = this.activeTour.tour.td_overnight === 1;
        this.component.showenddate = true;
        this.component.supplier = 'Boat Overnight';
        if (forced) {
          this.component.city_id = null;
          this.activeTour.itinerary[this.itIdx].hotel_id = null;
          this.component.itinerary.hotel_id = null;
        }
        this.getDriverNights();
        break;
      case 23:
        // console.log('Safety Check');
        this.setAllFalse();
        this.safety = true;
        if (!this.component.name) {
          const day = this.activeTour.days.find(d => '' + d.date === '' + this.component.start_date).nday;
          this.component.name = 'Safety Check #' + day + ' ' + this.component.city_name;
        }
        // Round the minutes to prox 15
        if (this.component.events[0].start_time?.includes(':')) {
          const tmp = this.component.events[0].start_time.split(':');
          const m = +tmp[1];
          const h = +tmp[0];
          let sc_hs, sc_mins;
          if ((Math.round(m / 15) * 15) === 60) {
            sc_mins = '00';
            sc_hs = (h + 1);
            if (sc_hs >= 24) {
              sc_hs = '00';
            } else {
              sc_hs = '' + sc_hs;
            }
          } else {
            sc_mins = '' + Math.round(m / 15) * 15;
            sc_hs = '' + h;
          }
          this.component.events[0].start_time = sc_hs.padStart(2, '0') + ':' + sc_mins.padStart(2, '0');
        }
        // if (!this.sc_hr || !this.sc_min) {
        const tmp2 = this.component.events[0].start_time?.split(':') || null;
        this.sc_hr = tmp2 ? tmp2[0] : '';
        this.sc_min = tmp2 ? tmp2[1] : '';
        // }
        break;
      case 24:
        // console.log('Birthday');
        this.setAllFalse();
        this.component.mailable = false;
        this.birthday = true;
        break;
      default:
        this.setAllFalse();
        this.other = true;
        break;
    }
    const idx = this.compTypes.findIndex(it => '' + it.type === '' + event.value);
    if (idx >= 0) {
      this.component.comp_type = this.compTypes[idx];
      if (event.source) {
        this.component.mailable = this.component.comp_type.mailable;
      }
    }
  }

  inputTime(event, ev, type) {
    let inputValue: string = event.target.value;
    if (event.data === ' ' && inputValue.length === 2) {
      inputValue = '0' + event.target.value[0] + ':';
    }
    if (inputValue.length === 2 && inputValue.indexOf(':') === -1) {
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2);
    }
    ev[type] = inputValue.substring(0, 5);
  }

  changeTime(event, ev, type) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.substring(0, 5);
    inputValue = inputValue.replace(/[^\d:]/g, '');
    if (inputValue === ':') {
      inputValue = null;
    }
    if (inputValue) {
      let [hours, minutes] = inputValue.split(':').map(Number);
      hours = isNaN(hours) ? 0 : hours;
      minutes = isNaN(minutes) ? 0 : minutes;
      const time = new Date();
      time.setHours(hours, minutes);
      inputValue = time.toLocaleTimeString(['es-ES'], {hour: '2-digit', minute: '2-digit'});
    }
    ev[type] = inputValue;
  }

  onFocusEndDate(comp: Components) {
    if (comp.start_date && !comp.end_date) {
      comp.end_date = comp.start_date;
    }
  }

  onFocusEnd(ev: Event) {
    if (ev.start_time && !ev.end_time && (!this.hotel || this.hotel && ev.name?.includes('Breakfast'))) {
      this.onAddEndTime(ev);
    }
  }

  onChangeEndDate() {
    if (!this.textTransform.isValidDate(this.component.end_date)) {
      this.loading = false;
      this.snackSvc.openSnackBar('Error. Enter correct end date');
      return;
    }
    if (this.component.end_date < this.component.start_date) {
      this.loading = false;
      this.snackSvc.openSnackBar('Error. Final date must be after start date');
      return;
    }
    if (this.hotel) {
      this.getDriverNights();
    }
  }

  validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getDriverNights() {
    this.Dnights = [];
    let req_nights = null;
    if (this.component.hotel_extras) {
      this.driverSleeps = true;
      this.showHExtra = true;
      req_nights = this.component.hotel_extras; // ,0,1,2,
    }
    const total_nights = (new Date(this.component.end_date).getTime() - new Date(this.component.start_date).getTime()) / (1000 * 3600 * 24);
    for (let j = 0; j < total_nights; j++) {
      const day = this.textTransform.addDays(new Date(this.component.start_date + 'T11:59:59.000Z'), j);
      this.Dnights.push({day: day, checked: req_nights ? req_nights.indexOf(',' + j + ',') >= 0 : false});
    }
    this.changeDS();
  }

  checkImageExists(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  }

  setDefaultPic() {
    this.checkImageExists(this.baseUrl + this.component.image).then((exists) => {
      if (!exists) {
        this.flgSvc.setFlag(this.currentUser.id, this.router.url,
          'Component (' + this.component.id + ') "' + this.component.name + '" failed loading image',
          'Failed image: ' + this.baseUrl + this.component.image);
        this.component.image = null;
        this.updateComponent('image');
      }
    });
  }

  createNewComponent(form?: boolean) {
    this.numberPax = this.component.npax ? +this.component.npax.split(' + ')[0] : +this.activeTour.tour.npax;
    this.numberStaff = this.component.npax ? +this.component.npax.split(' + ')[1] : 1;
    this.component.npax = this.numberPax + ' + ' + this.numberStaff;
    this.component.hotel_extras = this.d_nightsRequests;
    const data = {
      'email': this.currentUser.email,
      'prodid': this.activeTour.tour.prodid,
      'comp_type_id': this.component.comp_type_id,
      'name': this.component.name,
      'start_date': this.component.start_date,
      'end_date': this.component.end_date,
      'city_id': this.component.city_id,
      'npax': this.component.npax,
      'from': this.component.from,
      'to': this.component.to,
      'supplier': this.component.supplier,
      'hotel_extras': this.component.hotel_extras,
      'state': this.component.state,
      'recipients': this.component.recipients,
      'requests': this.component.requests,
      'recs_cc': this.component.recs_cc,
      'recs_bcc': this.component.recs_bcc,
      'day_id': this.component.events[0].day_id,
      'start_time': this.component.events[0].start_time,
      'end_time': this.component.events[0].end_time,
      'meet_place': this.component.events[0].meet_place,
      'private_notes': this.component.events[0].private_notes,
      'public_notes': this.component.events[0].public_notes,
      'visible': this.component.events[0].visible,
      'itinerary_id': this.component.itinerary_id,
      'description': this.component.description,
      'duplicate': true // force create not update
    };
    if (form) {
      data['visible'] = true;
      data['start_time'] = '00:00';
      data['show_times'] = false;
      data['confirmed_at'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'UTC');
      data['confirm_notes'] = 'This is a private check-list to cross out the names upon arriving at airport/hotel';
      data['private_notes'] = 'This is a private check-list to cross out the names upon arriving at airport/hotel';
    }
    // console.log(data);
    this.httpCompSvc.createComponentEvent(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.newComponent = false;
            this.component = res.results.component;
            this.component.showenddate = false;
            this.component.mailable = res.results.component.comp_type?.mailable;
            this.component.events[0].showendtime = false;
            this.component.events[0].day = res.results.events[0].day;
            this.component.city_name = this.activeTour.itinerary[this.itIdx].city.name;
            this.component.itinerary = this.activeTour.itinerary[this.itIdx];
            this.editBasicComp = !this.component.name;
            // Insert in order in activeTour for swiping properly
            this.activeTour.components.push(this.component);
            this.activeTour.components.sort((a, b) => (a.start_date < b.start_date ? -1 : 1));
            // Add newly created events to event list (unordered)
            this.activeTour.events = this.activeTour.events.concat(this.component.events);
            this.actTourSvc.setActiveTour(this.activeTour);
            const idx = this.component.events.findIndex(ev => ev.public_notes || ev.meet_place || ev.url);
            this.event_day_notes = this.event_day_notes ? this.event_day_notes : (idx >= 0 ? idx : 0);
            this.showPublicNotes = idx >= 0 || this.currentUser.id === 334; // Georgios Zach
            this.showPrivateNotes = !!this.component.confirm_notes;
            this.showDiet = this.component.requests?.includes('{di}');
            this.comIdx = this.activeTour.components.findIndex(it => +it.id === +this.component.id);
            this.onSelectionTypeChange({index: this.component.comp_type_id - 1, value: this.component.comp_type_id});
            const tour_route = 'tour/' + this.activeTour.tour.prodid;
            const day_route = '/day/' + this.component.events[0].day_id;
            this.router.navigate([tour_route + day_route + '/component/', res.results.component.id]);
            // days
            this.component.start_day = this.datePipe.transform(this.component.start_date, 'EEEE');
            this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
            // Form
            if (form) {
              this.onCreateForm(true);
            }
          } else {
            console.log('Error: component not created');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Creating component');
          this.flgSvc.setFlag(this.currentUser.id, this.router.url, 'createNewComponent createComponentEvent', JSON.stringify(error));
        });
  }

  togglePlacement() {
    const keyword = '{img_bef}';
    if (this.component.requests) {
      if (this.component.requests.includes(keyword)) {
        this.component.requests = this.component.requests.replace(keyword, '');
      } else {
        this.component.requests += keyword;
      }
    } else {
      this.component.requests = keyword;
    }
    this.updateComponent('requests');
  }

  deleteImage() {
    this.component.image = null;
    this.updateComponent('del_image');
  }

  uploadComponentImage(event) {
    const elem = event.target;
    const file = elem.files[0];
    if (!this.ngxPicaImageService.isImage(file)) {
      this.snackSvc.openSnackBar('Error. Invalid image');
      return;
    }
    if (elem.files.length > 0) {
      this.loading = true;
      this.ngxPicaService.compressImage(file, 1024)
        .subscribe((imageCompressed: File) => {
          const exif: ExifOptions = {
            forceExifOrientation: true
          };
          const options: NgxPicaResizeOptionsInterface = {
            exifOptions: exif,
            alpha: true,
            aspectRatio: {keepAspectRatio: true, forceMinDimensions: true}
          };
          this.ngxPicaService.resizeImage(imageCompressed, 400, 300, options)
            .subscribe((imageResized: File) => {
              let newFile: File;
              if (file.type.includes('gif')) {
                newFile = new File([imageCompressed], file.name, {type: imageResized.type});
              } else {
                newFile = new File([imageResized], file.name, {type: imageResized.type});
              }
              const formData = new FormData();
              formData.append('file', newFile);
              formData.append('req_id', '' + this.currentUser.id);
              formData.append('component_id', '' + this.component.id);
              this.httpCompSvc.sendComponentImage(formData)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  res => {
                    console.log(res);
                    if (res.status < 400) {
                      this.component.image = res.results.component.image;
                      this.component.updated_at = res.results.component.updated_at;
                      this.activeTour.components[this.comIdx].image = res.results.component.image;
                      this.activeTour.components[this.comIdx].updated_at = res.results.component.updated_at;
                      this.actTourSvc.setActiveTour(this.activeTour);
                    } else {
                      this.snackSvc.resultsElse(res);
                    }
                    this.loading = false;
                  },
                  error => {
                    this.loading = false;
                    console.log(error);
                    this.snackSvc.openSnackBar('ERROR. Updating component image');
                  });

            }, (err: NgxPicaErrorInterface) => {
              this.loading = false;
              console.log(err, 'image not resized');
              this.snackSvc.openSnackBar(err.err);
            });

        }, (err: NgxPicaErrorInterface) => {
          this.loading = false;
          console.log(err.err, 'image not compressed');
          this.snackSvc.openSnackBar(err.err);
        });
      elem.value = '';
    }
  }

  updRecs(e, type) {
    switch (type) {
      case 'rec':
        this.component.recipients = e;
        this.updateComponent('recipients');
        break;
      case 'rep':
        this.component.reply_recs = e;
        if (this.component.reply_recs) {
          this.component.reply_recs = this.component.reply_recs.replace(/,\s*$/, '').replace(/\s/g, '');
          this.reply_recipients = this.component.reply_recs.split(',');
        }
        break;
      case 'rcc':
        this.component.reply_ccs = e;
        if (this.component.reply_ccs) {
          this.component.reply_ccs = this.component.reply_ccs.replace(/,/g, ', ');
          this.reply_recs_cc = this.component.reply_ccs.split(',');
        }
        break;
      case 'cc':
        this.component.recs_cc = e;
        this.updateComponent('recs_cc');
        break;
      case 'bcc':
        this.component.recs_bcc = e;
        this.updateComponent('recs_bcc');
        break;
      default:
        break;
    }
  }

  updateComponent(txt?) {
    const val = this.validateInput();
    if (val) {
      this.snackSvc.openSnackBar(val);
      return;
    }
    if (this.changedType && this.component.comp_type_id !== 1 && this.component.comp_type_id !== 7 &&
      this.component.comp_type_id !== 22 && this.component.comp_type_id !== 18) {
      this.component.events = [this.component.events[0]];
    }
    if (this.dietaryIssues !== this.activeTour.tour.dietary_issues) {
      this.activeTour.tour.dietary_issues = this.dietaryIssues;
      this.actTourSvc.setActiveTour(this.activeTour);
      this.updateTour('dietary_issues');
    }
    // Remove last comma if present
    if (this.component.recipients) {
      this.component.recipients = this.component.recipients.replace(/,\s*$/, '').replace(/\s/g, '');
      this.recipients = this.component.recipients.split(',');
    }
    // CC list
    if (this.component.recs_cc) {
      this.component.recs_cc = this.component.recs_cc.replace(/,/g, ', ');
      this.cc_recipients = this.component.recs_cc.split(',');
    }
    // BBC list
    if (this.component.recs_bcc) {
      this.component.recs_bcc = this.component.recs_bcc.replace(/,/g, ', ');
      this.bcc_recipients = this.component.recs_bcc.split(',');
    }
    let data = {};
    this.component.hotel_extras = this.d_nightsRequests;
    if (!txt || txt === 'just_save') {
      this.component.npax = this.numberPax + ' + ' + this.numberStaff;
      data = {
        'comp_type_id': this.component.comp_type_id,
        'name': this.component.name,
        'itinerary_id': this.component.itinerary_id,
        'start_date': this.component.start_date,
        'end_date': this.component.end_date,
        'city_id': this.component.city_id,
        'npax': this.component.npax,
        'from': this.component.from,
        'to': this.component.to,
        'image': this.component.image,
        'supplier': this.component.supplier,
        'hotel_extras': this.component.hotel_extras,
        'state': this.component.state,
        'requests': this.component.requests,
        'subject': this.component.subject,
        'recipients': this.component.recipients,
        'recs_cc': this.component.recs_cc,
        'recs_bcc': this.component.recs_bcc,
        'description': this.component.description,
        'confirm_notes': this.component.confirm_notes,
      };
    } else if (txt === 'del_image') {
      data['image'] = null;
      data['del_image'] = true;
    } else if (txt === 'discard_draft') {
      data['message_id'] = null;
      data['html_body'] = null;
      data['sent_at'] = null;
      data['state'] = null;
    } else {
      data[txt] = this.component[txt];
    }
    // console.log(data);
    this.httpCompSvc.updateComponent(this.component.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 203) {
            // Possible hotels
            res.results.hotels.map(h => {
              if (!h.confirmed) {
                h.name = 'Select hotel';
              }
            });
            this.hotelsCity = res.results.hotels;
            this.suggOpts = res.results.optionals;
            this.same_type = res.results.same_type;
            this.component.description = res.results.component.description;
            if (txt !== 'confirmed_at' && txt !== 'discard_draft' && (!txt || txt === 'just_save')) {
              this.component.subject = res.results.component.subject;
              this.activeTour.components[this.comIdx] = res.results.component;
              this.updateEvents('full', txt === 'just_save');
            } else if (txt === 'del_image') {
              this.activeTour.components[this.comIdx].image = null;
            } else if (txt === 'discard_draft' || txt === 'mailable') {
              // null;
            } else {
              this.activeTour.components[this.comIdx][txt] = res.results.component[txt];
            }
            this.component.updated_at = res.results.component.updated_at;
            this.activeTour.components[this.comIdx].updated_at = res.results.component.updated_at;
            this.actTourSvc.setActiveTour(this.activeTour);
            this.showDiet = this.component.requests?.includes('{di}');
          } else {
            console.log('Error: component not updated');
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating component');
        });
  }

  onDeleteComponent(comp) {
    this.loading = true;
    this.httpCompSvc.deleteComponent(comp.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 204) {
            // If dismiss or no action delete the component + event from memory
            this.activeTour.components.splice(this.comIdx, 1);
            // Delete its associated events
            this.activeTour.events = this.activeTour.events.filter(e => '' + e.component_id !== '' + comp.id);
            this.actTourSvc.setActiveTour(this.activeTour);
            this.router.navigate(['../../'], {relativeTo: this.route});
          } else {
            // Error deleting city component in DB
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Deleting component');
        });
  }

  updateItinerary(idx) {
    const data = {
      'city_id': this.activeTour.itinerary[idx].city.id,
      'hotel_id': this.activeTour.itinerary[idx].hotel.id,
      'hotel_name': this.activeTour.itinerary[idx].hotel.name
    };
    // console.log(data);
    this.httpItin.updateItineraryTour(this.activeTour.itinerary[idx].id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.activeTour.itinerary[idx] = res.results.itinerary;
            this.actTourSvc.setActiveTour(this.activeTour);
            this.component.itinerary = res.results.itinerary;
            this.updateComponent('just_save');
          } else {
            console.log('Error: itinerary not updated');
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating itinerary');
        });
  }

  addNewHotel() {
    const dialogRef = this.dialog.open(AddHotelComponent, {
      autoFocus: true,
      data: {'city_id': this.component.city_id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const params = {
          'user_id': this.currentUser.id,
          'tour_id': this.activeTour.tour.id,
          'name': this.textTransform.titleCase(result['name']),
          'address': this.textTransform.titleCase(result['address']),
          'city_id': result['city_id'],
          'url': result['url'],
          'confirmed': true,
        };
        // console.log(params);
        this.httpHotSvc.createHotel(params)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                const hotel = new Hotel(res.results.hotel);
                const HotelIdx = this.hotelsCity.findIndex(x => x.name === hotel.name && x.address === hotel.address);
                if (HotelIdx < 0) {
                  this.hotelsCity.push(res.results.hotel);
                  this.component.itinerary.hotel_id = res.results.hotel.id;
                  this.onSelectionHotelChange({value: res.results.hotel.id});
                } else {
                  this.snackSvc.openSnackBar('Hotel already exists');
                }
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              console.log(error);
              this.snackSvc.openSnackBar('Error. Contact support');
              this.flgSvc.setFlag(this.currentUser.id, this.router.url, 'Error createHotel(): ' + error.toString(), params.toString());
            });
      }
    });
  }

  getCityHotels(city_id) {
    this.loadingHotels = true;
    this.httpHotSvc.getHotelsCity(city_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Possible hotels
            res.results.hotels.map(h => {
              if (!h.confirmed) {
                h.name = 'Select hotel';
              }
            });
            this.hotelsCity = res.results.hotels;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingHotels = false;
        },
        error => {
          this.loadingHotels = false;
          console.log(error);
          this.flgSvc.setFlag(this.currentUser.id, this.router.url, 'Error getCityHotels(): ' + error.toString(), city_id.toString());
        });
  }

  onCreateForm(att?: boolean) {
    this.loading = true;
    const data = {
      'component_id': this.component.id,
      'visible': false,
      'block': false,
      'multiple': false,
    };
    // console.log(data);
    this.httpForm.createForm(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.component.form = res.results.form;
            if (att) {
              this.createAttList();
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.flgSvc.setFlag(this.currentUser.id, this.router.url, 'Error onCreateForm(): ' + error.toString(), this.component.id.toString());
        });
  }

  async createAttList() {
    this.loading = true;
    try {
      const res = await this.httpForm.createAttList(this.component.form.id);
      console.log(res);
      if (res.status < 400) {
        this.component.form.questions.push(res.results.question);
        this.component.form.visible = !!res.results.question.form.visible;
        this.component.form.multiple = !!res.results.question.form.multiple;
        this.show_form = true;
        this.loading = false;
      } else {
        this.loading = false;
        console.log('*err: ' + this.component.form.id);
      }
    } catch (error) {
      this.loading = false;
      console.log('*err: ' + error);
    }
  }

  updForm(e) {
    this.component.form = e ? e : null;
    // console.log(this.component.form);
  }

  updAnswer(e) {
    const idx = this.component.form.questions.findIndex(q => q.id === e.value.id);
    this.component.form.questions[idx] = e;
  }

  onCopyFrom() {
    const dialogRef = this.dialog.open(CopyFromComponent, {
      minWidth: '50%',
      maxWidth: '100%',
      maxHeight: '90vh',
      autoFocus: true,
      data: {'component': this.component, 'user': this.currentUser, 'tour': this.activeTour.tour}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] === 'save') {
        this.component = result['component'];
        // Display extras
        const idx = this.component.events.findIndex(ev => ev.public_notes || ev.meet_place || ev.url);
        this.event_day_notes = this.event_day_notes ? this.event_day_notes : (idx >= 0 ? idx : 0);
        this.showPublicNotes = idx >= 0 || this.currentUser.id === 334; // Georgios Zach
        this.showPrivateNotes = !!this.component.confirm_notes;
        this.show_act_checkin = !!this.component.events[0].meet_time;
        this.showHExtra = !!this.component.hotel_extras || !!this.show_act_checkin;
        this.showDiet = this.component.requests?.includes('{di}');
        // days
        this.component.start_day = this.datePipe.transform(this.component.start_date, 'EEEE');
        this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
        // this.snackSvc.openSnackBar('Info copied');
        return;
      } else if (result && result['reason'] !== 'save' && result['reason'] !== 'close') {
        this.showPublicNotes = result['reason'] === 'Place' || result['reason'] === 'URL' || result['reason'] === 'Public notes';
        this.showPrivateNotes = result['reason'] === 'Private notes';
      } else {
        console.log('Didn\'t copy info');
      }
    });
  }

  onDuplicateComponent() {
    const dialogRef = this.dialog.open(CopyComponentComponent, {
      autoFocus: true,
      data: {'component': this.component, 'user': this.currentUser}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        this.activeTour.components.push(result.component);
        this.activeTour.events = [...this.activeTour.events, ...result.component.events];
        this.actTourSvc.setActiveTour(this.activeTour);
        this.snackSvc.openSnackBar('Component duplicated');
        return;
      } else {
        console.log('Don\'t copy component');
      }
    });
  }

  onTogglePending() {
    if (this.component.state === 'PEND') {
      this.component.state = null;
    } else {
      this.component.state = 'PEND';
    }
    this.updateComponent('state');
  }

  createEvent() {
    if (this.nextDayIdx < 0) {
      this.snackSvc.openSnackBar('ERROR. Already in last day');
      return;
    }
    const data = {
      'component_id': this.component.id,
      'url': this.component.events.slice(-1)[0].url,
      'public_notes': this.component.events.slice(-1)[0].public_notes,
      'meet_place': this.component.events.slice(-1)[0].meet_place,
      'visible': false,
      'show_times': false,
    };
    this.loading = true;
    if (this.component.comp_type_id === 1) {
      data['name'] = 'Breakfast in ' + this.component.supplier;
    } else if (this.component.comp_type_id === 7) {
      data['name'] = this.component.events.slice(-1)[0].name;
    } else {
      data['name'] = this.component.name;
    }
    const filteredEvents = this.component.events.filter(ev => ev.private_notes !== 'CO');
    this.nextDayIdx = this.activeTour.days.findIndex(d => d.nday === filteredEvents.slice(-1)[0].day.nday + 1);
    data['day_id'] = this.activeTour.days[this.nextDayIdx].id;
    // console.log(data);
    this.httpItin.createEvent(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.component.events.push(res.results.event);
            this.activeTour.events.push(res.results.event);
            this.actTourSvc.setActiveTour(this.activeTour);
            this.nextDayIdx = this.activeTour.days.findIndex(d => d.nday === this.component.events.slice(-1)[0].day.nday + 1);
            this.component.end_date = res.results.event.day.date;
            this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
            this.updateComponent('end_date');
            this.onChangeEndDate();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error creating event');
          this.loading = false;
        });
  }

  onDeleteEvent(event) {
    this.loading = true;
    this.httpItin.deleteEvent(this.currentUser.id, event.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          const evIdx = this.component.events.findIndex(ev => ev.id === event.id);
          const evIdx2 = this.activeTour.events.findIndex(ev => ev.id === event.id);
          if (this.event_day_notes === evIdx) {
            this.event_day_notes = 0;
          }
          this.component.events.splice(evIdx, 1);
          this.activeTour.events.splice(evIdx2, 1);
          this.actTourSvc.setActiveTour(this.activeTour);
          this.nextDayIdx = this.activeTour.days.findIndex(d => d.nday === this.component.events.slice(-1)[0].day.nday + 1);
          this.component.end_date = this.component.events.slice(-1)[0].day.date;
          this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
          this.updateComponent('end_date');
          this.onChangeEndDate();
        } else {
          if (res.results) {
            this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
          } else {
            this.snackSvc.openSnackBar('ERROR. Deleting event');
            console.log(res.message.toString());
          }
        }
        this.loading = false;
      }, error => {
        console.log(error);
        this.snackSvc.openSnackBar('ERROR. Deleting event');
        this.loading = false;
      });
  }

  onEditBasicComp() {
    this.editBasicComp = !this.editBasicComp;
  }

  onOKeditBasicComp() {
    if (this.hotel && !this.component.itinerary.hotel) {
      this.snackSvc.openSnackBar('Error. Invalid hotel');
      return;
    }
    this.editBasicComp = !this.editBasicComp;
    this.component.npax = this.numberPax + ' + ' + this.numberStaff;
    const data = {
      'name': this.component.name,
      'supplier': this.component.supplier,
      'from': this.component.from,
      'to': this.component.to,
      'npax': this.component.npax,
      'comp_type_id': this.component.comp_type_id,
      'mailable': this.component.mailable,
      'image': this.component.image,
      'city_id': this.component.city_id,
      'description': this.component.description,
    };
    this.httpCompSvc.updateComponent(this.component.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 203) {
            const idx = this.compTypes.findIndex(it => '' + it.type === '' + res.results.component.comp_type_id);
            this.component.comp_type = this.compTypes[idx];
            this.same_type = res.results.same_type;
            this.component.updated_at = res.results.component.updated_at;
            this.activeTour.components[this.comIdx].name = res.results.component.name;
            if (this.component.comp_type.id !== 1) {
              this.component.events[0].name = res.results.component.name;
              const evIdx = this.activeTour.events.findIndex(ev => '' + ev.component_id === '' + res.results.component.id);
              this.activeTour.events[evIdx].name = res.results.component.name;
            }
            if (!this.component.mailable) {
              if (this.component.message_id) {
                this.deleteMessage(this.component.message_id, this.component.conversation_id);
              } else {
                this.cleanMessageComp();
              }
            }
            this.component.subject = res.results.component.subject;
            this.activeTour.components[this.comIdx].subject = res.results.component.subject;
            this.activeTour.components[this.comIdx].supplier = res.results.component.supplier;
            this.activeTour.components[this.comIdx].npax = res.results.component.npax;
            this.activeTour.components[this.comIdx].from = res.results.component.from;
            this.activeTour.components[this.comIdx].to = res.results.component.to;
            this.activeTour.components[this.comIdx].comp_type = this.compTypes[idx];
            this.activeTour.components[this.comIdx].comp_type_id = res.results.component.comp_type_id;
            this.activeTour.components[this.comIdx].mailable = res.results.component.mailable;
            this.activeTour.components[this.comIdx].image = res.results.component.image;
            this.activeTour.components[this.comIdx].city_id = res.results.component.city_id;
            this.activeTour.components[this.comIdx].updated_at = res.results.component.updated_at;
            this.actTourSvc.setActiveTour(this.activeTour);
            this.nextDayIdx = this.activeTour.days.findIndex(d => d.nday === this.component.events.slice(-1)[0].day.nday + 1);
          } else {
            console.log('Error: component not updated');
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating component');
        });
  }

  onEditSubject() {
    if (this.component.requests) {
      if (!this.component.requests.includes('{sub}')) {
        this.component.requests += '{sub}';
      }
    } else {
      this.component.requests = '{sub}';
    }
  }

  onModifyTemplates(txt?) {
    const where = txt ? txt : 'General fields';
    this.router.navigate(['user', 'templates'], {queryParams: {returnUrl: window.location.pathname, temp: where}});
  }

  editURL(e) {
    if (e.target.value.includes('https://forms.gle/')) {
      this.snackSvc.openSnackBar('Error. Use long Google form URL ');
      this.component.events[this.event_day_notes].url = null;
    } else {
      if (this.td_optional &&
        (e.target.value.includes('docs.google.com/forms/d') || e.target.value.includes('forms.office.com'))) {
        this.snackSvc.openSnackBar('Error. No forms on TD Opts');
        this.component.events[this.event_day_notes].url = null;
      } else {
        this.component.events[this.event_day_notes].url = e.target.value;
      }
    }
  }

  insertRandomLetters(number: number): string {
    return number.toString().split('').map((char, index, arr) =>
      char + (index < arr.length - 1 ? String.fromCharCode(97 + Math.floor(Math.random() * 26)) : '')
    ).join('');
  }

  openWebLink() {
    const encoded = btoa(this.insertRandomLetters(this.component.id)).replace(/=*/, '');
    window.open(this.apiUrl + 'component/html/' + encoded, '_blank');
  }

  onFixNotes() {
    for (const ev of this.component.events) {
      ev.public_notes = ev.public_notes.replace(/<\/p><p>/g, '<br>');
    }
  }

  // MESSAGES

  onCleanMessageComp() {
    this.loading = true;
    // Show snackbar to undo clear
    const snackbarRef = this.snackSvc.openSnackBar('Clear all messages!?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (reason.dismissedByAction) {
          this.cleanMessageComp();
        } else {
          setTimeout(() => {
            this.loading = false;
          }, 1);
        }
      });
  }

  cleanMessageComp() {
    this.show_reply_box = false;
    const data = {
      'message_id': null,
      'scheduled_send': null,
      'conversation_id': null,
      'html_body': null,
      'web_link': null,
      'sent_at': null,
      'state': null,
      'requests': this.component.requests?.replace('{FRL}', '')
    };
    this.httpCompSvc.updateComponent(this.component.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 203) {
            this.component.updated_at = res.results.component.updated_at;
            this.cleanMessage(this.comIdx);
            this.htmlEdited = false;
            this.actTourSvc.setActiveTour(this.activeTour);
            this.deleteAllMessages();
            this.messages = [];
          } else {
            console.log('Error: component message not cleaned');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Cleaning message component');
        });
  }

  async outlookGetConvos(att?) {
    this.msg_loading = true;
    this.att_loading = true;
    let list_orphans = '';
    if (this.activeTour.message_list && this.activeTour.message_list?.orphan_convos) {
      for (const convo of this.activeTour.message_list.orphan_convos) {
        list_orphans = list_orphans + ',' + convo.convo;
      }
    }
    const content = {
      'user_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'component_id': this.component.id,
    };
    if (list_orphans.length > 0) {
      content['list_orphans'] = list_orphans.replace(/^,|,$/g, '');
    }
    // console.log(content);
    const res = await this.outlookSvc.getConvos(content);
    console.log(res);
    if (res.status === 200) {
      this.msg_loading = false;
      if (!att) {
        this.att_loading = false;
      }
      const adm = this.currentUser.view_as || this.activeTour.tour.user_id !== this.currentUser.id;
      if (!adm && Array.isArray(res.results.messages) && res.results.messages.length < 1 && this.component.message_id) {
        this.cleanMessageComp();
        return;
      }
      if (Array.isArray(res.results.messages)) {
        this.messages = res.results.messages.sort((a, b) => (a.sentDateTime < b.sentDateTime ? -1 : 1));
        this.messages.map(item => {
          item.bodyPreview = this.removeEFWarnPre(item.bodyPreview);
          item.body.content = this.removeEFWarn(item.body.content);
          item.folded = item.folded ? item.folded : true;
          item.showDeets = false;
          if (item.singleValueExtendedProperties) {
            item.scheduled_date = item.singleValueExtendedProperties.find(p => p.id === 'SystemTime 0x3fef').value;
          }
        });
        if (this.messages.some(item => !item.singleValueExtendedProperties)
          && this.component.sent_at && this.component.state !== 'PEND') {
          this.component.state = 'PEND';
          this.activeTour.components[this.comIdx].state = 'PEND';
          this.actTourSvc.setActiveTour(this.activeTour);
          this.updateComponent('state');
        }
        if (this.messages.some(item => item.singleValueExtendedProperties)
          && this.component.sent_at && this.component.state !== 'SCHE') {
          this.component.state = 'SCHE';
          this.activeTour.components[this.comIdx].state = 'SCHE';
          this.actTourSvc.setActiveTour(this.activeTour);
          this.updateComponent('state');
        }
        const comp = this.component;
        this.messages = this.messages.filter(function (m) {
          // If in messages I find a recipient that is myself or matches ANY of my components' recipients
          if (m.body.content.includes('CID_START' + comp.id + 'CID_END') || (comp.conversation_id === m.conversationId)) {
            return m;
          }
        });
      }
    } else {
      this.msg_loading = false;
      if (!att) {
        this.att_loading = false;
      }
      this.snackSvc.resultsElse(res);
    }
  }

  toggleExpandMessage(msg) {
    // console.log(msg)
    msg.folded = !msg.folded;
    if (!msg.isRead) {
      this.outlookSetReadStatusMsg(msg, 'set_read');
    }
    const idx = this.searchElementMsgsRefs.toArray().findIndex(it => '' + it.nativeElement.id === '' + msg.id);
    if (idx >= 0) {
      setTimeout(() => {
        this.searchElementMsgsRefs.toArray()[idx].nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest'});
      }, 1);
    }
  }

  createMsgUpdComp(type?: string) {
    if (this.hotel && !this.component?.itinerary?.hotel?.confirmed) {
      this.editBasicComp = true;
      this.snackSvc.openSnackBar('Select a hotel, or add new');
      return;
    }
    // First time composing email
    if (!this.component.message_id && type !== 'just_save') {
      this.forceUserContactsList();
    }
    this.msg_loading = true;
    this.att_loading = true;
    const val = this.validateInput('msg');
    if (val) {
      this.snackSvc.openSnackBar(val);
      this.msg_loading = false;
      this.att_loading = false;
      return;
    }
    if (this.changedType && this.component.comp_type_id !== 1 && this.component.comp_type_id !== 7 &&
      this.component.comp_type_id !== 18 && this.component.comp_type_id !== 22) {
      this.component.events = [this.component.events[0]];
    }
    if (this.dietaryIssues !== this.activeTour.tour.dietary_issues) {
      this.activeTour.tour.dietary_issues = this.dietaryIssues;
      this.actTourSvc.setActiveTour(this.activeTour);
      this.updateTour('dietary_issues');
    }
    // Remove last comma if present
    if (this.component.recipients) {
      this.component.recipients = this.component.recipients.replace(/,\s*$/, '').replace(/\s/g, '');
      this.recipients = this.component.recipients.split(',');
    }
    // CC list
    if (this.component.recs_cc) {
      this.component.recs_cc = this.component.recs_cc.replace(/,/g, ', ');
      this.cc_recipients = this.component.recs_cc.split(',');
    }
    // BBC list
    if (this.component.recs_bcc) {
      this.component.recs_bcc = this.component.recs_bcc.replace(/,/g, ', ');
      this.bcc_recipients = this.component.recs_bcc.split(',');
    }
    this.component.npax = this.numberPax + ' + ' + this.numberStaff;
    this.component.hotel_extras = this.d_nightsRequests;
    const data = {
      'comp_type_id': this.component.comp_type_id,
      'name': this.component.name,
      'start_date': this.component.start_date,
      'end_date': this.component.end_date,
      'confirm_notes': this.component.confirm_notes,
      'mailable': this.component.mailable,
      'image': this.component.image,
      'city_id': this.component.city_id,
      'npax': this.component.npax,
      'from': this.component.from,
      'to': this.component.to,
      'supplier': this.component.supplier,
      'hotel_extras': this.component.hotel_extras,
      'state': this.component.state,
      'subject': this.component.subject,
      'html_body': this.component.html_body,
      'requests': this.component.requests,
      'recipients': this.component.recipients,
      'recs_cc': this.component.recs_cc,
      'recs_bcc': this.component.recs_bcc,
      'description': this.component.description,
    };
    // console.log(data);
    this.httpCompSvc.updateComponent(this.component.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 203) {
            this.editBasicComp = false;
            this.component.showenddate = this.component.start_date !== this.component.end_date;
            this.component.subject = res.results.component.subject;
            this.component.updated_at = res.results.component.updated_at;
            this.component.description = res.results.component.description;
            if (res.results.component.events.length > this.component.events) {
              for (const [i, ev] of res.results.components.events) {
                if (i > 0) {
                  this.component.events.push(ev);
                }
              }
            }
            this.same_type = res.results.same_type;
            this.activeTour.components[this.comIdx] = this.component;
            this.actTourSvc.setActiveTour(this.activeTour);
            this.suggOpts = res.results.optionals;
            this.htmlEdited = this.component.html_body && !this.component.html_body?.includes('<div id=') || false;
            this.nextDayIdx = this.activeTour.days.findIndex(d => d.nday === this.component.events.slice(-1)[0].day?.nday + 1);
            this.updateEvents('full', type === 'just_save');
          } else {
            this.msg_loading = false;
            this.att_loading = false;
            console.log('Component not updated in database');
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.msg_loading = false;
          this.att_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Saving component');
        });
  }

  outlookUpdateMessage() {
    const content = {
      'req_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'component_id': this.component.id,
      'update_message': true,
    };
    // console.log(content);
    this.outlookSvc.updateMessage(content)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.msg_loading = false;
          this.att_loading = false;
          console.log(res);
          if (res.status === 200) {
            this.component.message_id = res.results.messages.id;
            this.component.web_link = res.results.messages.webLink;
            this.component.html_body = res.results.body_html;
            this.htmlEdited = this.component.html_body && !this.component.html_body?.includes('<div id=') || false;
            // Update fields in memory
            this.activeTour.components[this.comIdx]['sent_at'] = this.component.sent_at;
            this.activeTour.components[this.comIdx]['web_link'] = this.component.web_link;
            this.activeTour.components[this.comIdx]['html_body'] = this.component.html_body;
            this.actTourSvc.setActiveTour(this.activeTour);
            // Scroll to mail
            setTimeout(() => {
              this.searchElementBodyRef?.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
            }, 1);
            this.edFocus = false;
          }
        },
        err => {
          console.log(err);
          this.edFocus = false;
          this.msg_loading = false;
          this.att_loading = false;
          this.snackSvc.openSnackBar('Error updating message. Contact support');
          this.flgSvc.setFlag(this.currentUser.id, this.router.url, 'Error updating message: ' + err.toString(), content.toString());
        });
  }

  async outlookAttachFile(files: FileList) {
    this.att_loading = true;
    const fileToUpload: File = files.item(0);
    if (files.length > 0) {
      const formData: FormData = new FormData();
      formData.append('session_id', localStorage.getItem('session_id'));
      formData.append('component_id', '' + this.component.id);
      formData.append('req_id', '' + this.currentUser.id);
      formData.append('file', fileToUpload, fileToUpload.name);
      const res = await this.outlookSvc.attachFile(formData);
      console.log(res);
      if (res.status === 200) {
        this.outlookGetConvos('att');
        if (fileToUpload.name.includes('FinalRoomingList')) {
          if (this.component.requests) {
            if (!this.component.requests.includes('{FRL}')) {
              this.component.requests += '{FRL}';
              // this.createMsgUpdComp();
            }
          } else {
            this.component.requests = '{FRL}';
            // this.createMsgUpdComp();
          }
        } else {
          this.att_loading = false;
        }
        this.att_loading = false;
      } else {
        this.att_loading = false;
        this.snackSvc.resultsElse(res);
      }
    } else {
      this.att_loading = false;
    }
    this.myInput1Variable.nativeElement.value = '';
  }

  outlookRemoveFile(attachment_id: string, name: string) {
    this.att_loading = true;
    const content = {
      'req_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'component_id': this.component.id,
      'attachment_id': attachment_id,
    };
    // console.log(content);
    this.outlookSvc.removeAttachment(content)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const attachIdx = this.messages[0].attachments.findIndex(i => '' + i.id === '' + attachment_id);
            this.messages[0].attachments.splice(attachIdx, 1);
            if (name.includes('FinalRoomingList')) {
              this.component.requests = this.component.requests.replace('{FRL}', '');
            } else {
              this.att_loading = false;
            }
            this.att_loading = false;
          } else {
            if (res.results?.includes('ErrorItemNotFound')) {
              const attachIdx = this.messages[0].attachments.findIndex(i => '' + i.id === '' + attachment_id);
              this.messages[0].attachments.splice(attachIdx, 1);
            } else {
              this.outlookGetConvos();
            }
            this.att_loading = false;
            this.snackSvc.resultsElse(res);
          }
        },
        err => {
          this.att_loading = false;
          console.log(err);
        });
  }

  downloadAttachedFile(data: any): void {
    const byteCharacters = atob(data.contentBytes);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob: Blob = new Blob([byteArray], {type: data.contentType});
    const fileName = data.name;
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  previewFile(at) {
    let res: SafeUrl = 'assets/images/attach.jpg';
    if (at.contentType.includes('image')) {
      const url = 'data:' + at.contentType + ';charset=utf-8;base64,' + at.contentBytes;
      res = this.sanitizer.bypassSecurityTrustUrl(url);
    }
    return res;
  }

  outlookSetReadStatusMsg(msg, set) {
    this.msg_loading = true;
    this.att_loading = true;
    msg.folded = msg.isRead;
    const content = {
      'req_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'component_id': this.component.id,
      'msg_id': msg.id,
    };
    content[set] = set;
    this.outlookSvc.updateMessage(content)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.msg_loading = false;
          this.att_loading = false;
          console.log(res);
          if (res.status === 200) {
            msg.isRead = res.results.messages.isRead;
            const msgIdx = this.messages.findIndex(item => '' + item.id === '' + res.results.messages.id);
            this.messages[msgIdx].isRead = msg.isRead;
            if (set === 'set_read') {
              this.activeTour.message_list.n_new_convos.splice(this.activeTour.message_list.n_new_convos.indexOf(msg.conversationId), 1);
            } else {
              this.activeTour.message_list.n_new_convos.push(msg.conversationId);
            }
            this.actTourSvc.setActiveTour(this.activeTour);
          }
        },
        err => {
          this.msg_loading = false;
          this.att_loading = false;
          console.log(err);
        });
  }

  async outlookSendMessage(msg: Message) {
    this.loading = true;
    const data = {
      'user_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'component_id': this.component.id,
      'message_id': msg.id
    };
    // console.log(data);
    const res = await this.outlookSvc.sendMessage(data);
    console.log(res);
    if (res.status === 200) {
      this.loading = false;
      this.outlookGetConvos();
      this.snackSvc.openSnackBar('Message sent');
    } else {
      this.loading = false;
    }
  }

  async outlookSendDriverEmail() {
    this.loading = true;
    const data = {
      'req_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'component_id': this.component.id,
    };
    const res = await this.outlookSvc.sendDriverEmail(data);
    console.log(res);
    if (res.status === 200) {
      this.loading = false;
      this.outlookGetConvos();
      // console.log('Message scheduled');
    } else {
      this.loading = false;
      this.snackSvc.openSnackBar('Error. Driver mail not scheduled');
    }
  }

  async outlookSendBreakfastEmail() {
    this.loading = true;
    const data = {
      'req_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'component_id': this.component.id,
    };
    const res = await this.outlookSvc.sendBfastEmail(data);
    console.log(res);
    if (res.status === 200) {
      this.loading = false;
      this.outlookGetConvos();
      // console.log('Message scheduled');
    } else {
      this.loading = false;
      this.snackSvc.openSnackBar('Error. BF mail not scheduled');
    }
  }

  async moveOutlookMessage(data, to) {
    this.msg_loading = true;
    this.att_loading = true;
    const res = await this.outlookSvc.moveMessage(data);
    console.log(res);
    if (res.status === 200) {
      // Update web_link field in memory
      this.component.web_link = res.results.messages.webLink;
      this.component.message_id = res.results.messages.id;
      this.component.conversation_id = res.results.messages.conversationId;
      const itemIndex = this.activeTour.components.findIndex(item => '' + item.id === '' + data['component_id']);
      this.activeTour.components[itemIndex]['message_id'] = res.results.messages.id;
      this.activeTour.components[itemIndex]['web_link'] = res.results.messages.webLink;
      this.activeTour.components[itemIndex]['conversation_id'] = res.results.messages.conversationId;
      this.actTourSvc.setActiveTour(this.activeTour);
      // Send scheduled get driver email if bus guss, transfer, arrivals or departures
      // If Bfast box, schedule send breakfast message
      if (this.currentUser.user_settings.send_driver) {
        if (this.component.comp_type.type === 4 || this.component.comp_type.type === 12 ||
          this.component.comp_type.type === 16 || this.component.comp_type.type === 17) {
          this.outlookSendDriverEmail();
        } else if (this.component?.events?.some(ev => ev.private_notes === 'BFB') && this.component?.comp_type?.type === 1) {
          this.outlookSendBreakfastEmail();
        } else {
          this.outlookGetConvos();
        }
      } else {
        this.outlookGetConvos();
      }
      this.msg_loading = false;
      this.att_loading = false;
    } else {
      to = to + 1000;
      console.log('Not found, trying again in ', to / 1000, ' secs');
      if (to < 8000) {
        setTimeout(() => {
          this.moveOutlookMessage(data, to);
        }, to);
      } else {
        this.msg_loading = false;
        this.att_loading = false;
      }
    }
  }

  fakePreviewMessage() {
    this.httpCompSvc.getPreviewComponent(this.component.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        resfake => {
          console.log(resfake);
          if (resfake.status === 200) {
            this.component.message_id = resfake.results.message_id;
            this.component.html_body = resfake.results.body_html;
            this.htmlEdited = this.component.html_body && !this.component.html_body?.includes('<div id=') || false;
            // Update fields in memory
            this.activeTour.components[this.comIdx]['message_id'] = this.component.message_id;
            this.activeTour.components[this.comIdx]['html_body'] = this.component.html_body;
            this.actTourSvc.setActiveTour(this.activeTour);
          }
          this.edFocus = false;
          this.msg_loading = false;
          this.att_loading = false;
        },
        err1 => {
          this.edFocus = false;
          this.msg_loading = false;
          this.att_loading = false;
          console.log(err1);
        });
  }

  outlookWriteMessage() {
    const content = {
      'req_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'folder_id': localStorage.getItem('folder_id'),
      'component_id': this.component.id,
    };
    // console.log(content);
    this.outlookSvc.writeMessage(content)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.msg_loading = false;
          this.att_loading = false;
          console.log(res);
          if (res.status === 200) {
            this.component.message_id = res.results.messages.id;
            this.component.conversation_id = res.results.messages.conversationId;
            this.component.web_link = res.results.messages.webLink;
            this.component.html_body = res.results.body_html;
            this.htmlEdited = this.component.html_body && !this.component.html_body?.includes('<div id=') || false;
            // Update fields in memory
            this.activeTour.components[this.comIdx]['message_id'] = this.component.message_id;
            this.activeTour.components[this.comIdx]['sent_at'] = this.component.sent_at;
            this.activeTour.components[this.comIdx]['web_link'] = this.component.web_link;
            this.activeTour.components[this.comIdx]['conversation_id'] = this.component.conversation_id;
            this.activeTour.components[this.comIdx]['html_body'] = this.component.html_body;
            this.actTourSvc.setActiveTour(this.activeTour);
            // Scroll to mail
            setTimeout(() => {
              this.searchElementBodyRef?.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
            }, 1);
          } else {
            this.msg_loading = false;
            this.att_loading = false;
            const code = res.message?.toString().split('"code":"')[1]?.split('",')[0];
            if (code?.includes('ErrorSavedItemFolderNotFound')) {
              this.tourSvc.onCreateTourFolder(false);
              this.snackSvc.openSnackBar('Resetting folder. Please, try again');
              return;
            } else {
              this.snackSvc.openSnackBar(res.message);
              this.flgSvc.setFlag(this.currentUser.id, this.router.url, 'Error outlookWriteMessage: ' + code, '' + res.message);
            }
          }
        },
        err => {
          console.log(err);
          this.msg_loading = false;
          this.att_loading = false;
          this.snackSvc.openSnackBar('Error: ' + err);
          this.flgSvc.setFlag(this.currentUser.id, this.router.url, 'Error writing message', '' + err);
        });
  }

  goPay() {
    this.router.navigate(['summary', this.activeTour.tour.prodid], {queryParams: {returnUrl: window.location.pathname, ref: true}});
  }

  private getDialogWidths() {
    if (this.breakpointObserver.isMatched('(max-width: 575px)')) {
      return {minWidth: '92vw', maxWidth: '92vw'}; // xs
    } else if (this.breakpointObserver.isMatched('(max-width: 767px)')) {
      return {minWidth: '85vw', maxWidth: '90vw'}; // sm
    } else if (this.breakpointObserver.isMatched('(max-width: 991px)')) {
      return {minWidth: '60vw', maxWidth: '70vw'}; // md
    } else if (this.breakpointObserver.isMatched('(max-width: 1199px)')) {
      return {minWidth: '50vw', maxWidth: '60vw'}; // lg
    } else {
      return {minWidth: '35vw', maxWidth: '45vw'}; // xl
    }
  }

  onScheduleSend() {
    if (!this.component.recipients) {
      this.snackSvc.openSnackBar('Error. Recipients needed');
      return;
    }
    const dialogRef = this.dialog.open(ScheduleSendComponent, {
      ...this.getDialogWidths(),
      data: {
        'isMobile': this.isMobile,
        'isSafari': this.isSafari,
        'timezone': this.component.city.timezone,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'close') {
        this.component.scheduled_send = result['scheduled_send'];
        this.sendMessage();
      } else {
        // console.log('Didnt schedule send');
      }
    });
  }

  sendMessage() {
    this.loading = true;
    const val = this.validateInput('msg');
    if (val) {
      this.snackSvc.openSnackBar(val);
      this.loading = false;
      return;
    }
    if (!this.component.recipients) {
      this.snackSvc.openSnackBar('Error. Recipients needed');
      this.loading = false;
      return;
    }
    if (this.changedType && this.component.comp_type_id !== 1 && this.component.comp_type_id !== 7 &&
      this.component.comp_type_id !== 18 && this.component.comp_type_id !== 22) {
      this.component.events = [this.component.events[0]];
    }
    if (this.dietaryIssues !== this.activeTour.tour.dietary_issues) {
      this.activeTour.tour.dietary_issues = this.dietaryIssues;
      this.actTourSvc.setActiveTour(this.activeTour);
      this.updateTour('dietary_issues');
    }
    // Remove last comma if present
    if (this.component.recipients) {
      this.component.recipients = this.component.recipients.replace(/,\s*$/, '').replace(/\s/g, '');
      this.recipients = this.component.recipients.split(',');
    }
    // CC list
    if (this.component.recs_cc) {
      this.component.recs_cc = this.component.recs_cc.replace(/,/g, ', ');
      this.cc_recipients = this.component.recs_cc.split(',');
    }
    // BBC list
    if (this.component.recs_bcc) {
      this.component.recs_bcc = this.component.recs_bcc.replace(/,/g, ', ');
      this.bcc_recipients = this.component.recs_bcc.split(',');
    }
    this.component.npax = this.numberPax + ' + ' + this.numberStaff;
    this.component.hotel_extras = this.d_nightsRequests;
    const data = {
      'comp_type_id': this.component.comp_type_id,
      'name': this.component.name,
      'start_date': this.component.start_date,
      'end_date': this.component.end_date,
      'city_id': this.component.city_id,
      'npax': this.component.npax,
      'from': this.component.from,
      'to': this.component.to,
      'supplier': this.component.supplier,
      'html_body': this.component.html_body,
      'hotel_extras': this.component.hotel_extras,
      'requests': this.component.requests,
      'subject': this.component.subject,
      'recipients': this.component.recipients,
      'recs_cc': this.component.recs_cc,
      'recs_bcc': this.component.recs_bcc,
      'description': this.component.description,
      'scheduled_send': this.component.scheduled_send,
    };
    // console.log(data);
    this.httpCompSvc.updateComponent(this.component.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 203) {
            this.component.subject = res.results.component.subject;
            this.component.updated_at = res.results.component.updated_at;
            this.activeTour.components[this.comIdx] = res.results.component;
            this.changedType = false;
            // Check if paid
            const payments = this.activeTour.orders.filter(o => o.status === 'Complete');
            if (payments.length <= 0) {
              this.loading = false;
              const snackbarPayRef = this.snackSvc.openSnackBar('Purchase to send mails', 'GO');
              snackbarPayRef.afterDismissed()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(reason => {
                  if (reason.dismissedByAction) {
                    this.goPay();
                  }
                });
              return;
            }
            // Check if BETA
            if (this.beta) {
              // Show snackbar to undo Send
              const snackbarRef2 = this.snackSvc.openSnackBar('Sending message...', 'Undo', this.currentUser.user_settings.secs_undo);
              snackbarRef2.afterDismissed()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(reason => {
                  if (!reason.dismissedByAction) {
                    this.updateEvents('full', true);
                    const update = {
                      'component_id': this.component.id,
                      'req_id': this.currentUser.id,
                    };
                    if (!this.component.sent_at) {
                      this.outlookSvc.sendLaravelMessage(update)
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe(
                          res1 => {
                            this.loading = false;
                            console.log(res1);
                            if (res1.status === 203) {
                              this.messages.push(res1.results.messages);
                              this.component.html_body = res1.results.body_html;
                              this.component.message_id = res1.results.messages.id;
                              this.component.conversation_id = res1.results.messages.conversation_id;
                              this.component.mail_provider = 'Planafy';
                              this.component.web_link = '';
                              this.component.state = 'PEND';
                              this.component.sent_at = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
                              // this.component.sent_at = res1.results.messages.createdDateTime;
                              this.htmlEdited = this.component.html_body && !this.component.html_body?.includes('<div id=') || false;
                              // Beautify recipients
                              this.component.recipients = this.component.recipients.replace(/,/g, ', ');
                              this.snackSvc.openSnackBar('Message sent');
                              // Update fields in memory
                              this.activeTour.components[this.comIdx]['message_id'] = this.component.message_id;
                              this.activeTour.components[this.comIdx]['conversation_id'] = this.component.conversation_id;
                              this.activeTour.components[this.comIdx]['mail_provider'] = this.component.mail_provider;
                              this.activeTour.components[this.comIdx]['sent_at'] = this.component.sent_at;
                              this.activeTour.components[this.comIdx]['html_body'] = this.component.html_body;
                              this.activeTour.components[this.comIdx]['web_link'] = this.component.web_link;
                              this.actTourSvc.setActiveTour(this.activeTour);
                            } else {
                              this.snackSvc.resultsElse(res);
                            }
                          },
                          err1 => {
                            console.log(err1);
                            this.loading = false;
                            this.component.sent_at = null;
                            this.component.state = null;
                            this.snackSvc.openSnackBar('Error sending message');
                          });
                    } else {
                      this.snackSvc.openSnackBar('Message already sent!');
                    }
                  } else {
                    setTimeout(() => {
                      this.loading = false;
                      this.msg_loading = false;
                      this.att_loading = false;
                    }, 1);
                  }
                });
              return;
            }
            // Show snackbar to undo Send
            const snackbarRef = this.snackSvc.openSnackBar('Sending message...', 'Undo', this.currentUser.user_settings.secs_undo);
            snackbarRef.afterDismissed()
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(reason => {
                  if (!reason.dismissedByAction) {
                    this.updateEvents('full', true);
                    if (!this.component.sent_at) {
                      const update = {
                        'req_id': this.currentUser.id,
                        'session_id': localStorage.getItem('session_id'),
                        'folder_id': localStorage.getItem('folder_id'),
                        'component_id': this.component.id,
                      };
                      this.outlookSvc.sendUpdMessage(update)
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe(
                          res1 => {
                            console.log(res1);
                            if (res1.status === 200) {
                              this.component.html_body = res1.results.body_html;
                              this.component.web_link = res1.results.messages.webLink;
                              this.component.subject = res1.results.messages.subject;
                              this.component.state = res1.results.component.state;
                              this.component.sent_at = res1.results.component.sent_at;
                              this.component.scheduled_send = res1.results.component.scheduled_send;
                              this.htmlEdited = this.component.html_body && !this.component.html_body?.includes('<div id=') || false;
                              // Beautify recipients
                              this.component.recipients = this.component.recipients.replace(/,/g, ', ');
                              if (this.component.scheduled_send) {
                                this.snackSvc.openSnackBar('Message scheduled');
                              } else {
                                this.snackSvc.openSnackBar('Message sent');
                              }
                              // Update fields in memory
                              this.activeTour.components[this.comIdx]['state'] = this.component.state;
                              this.activeTour.components[this.comIdx]['subject'] = this.component.subject;
                              this.activeTour.components[this.comIdx]['sent_at'] = this.component.sent_at;
                              this.activeTour.components[this.comIdx]['web_link'] = this.component.web_link;
                              this.activeTour.components[this.comIdx]['html_body'] = this.component.html_body;
                              this.activeTour.components[this.comIdx]['scheduled_send'] = this.component.scheduled_send;
                              this.actTourSvc.setActiveTour(this.activeTour);
                              // Add contact if not exist
                              const rcs = this.component.recipients.split(',');
                              for (const r of rcs) {
                                if (!this.currentUser.contacts.list.some(e => '' + e.email === '' + r)) {
                                  const c = new Contact();
                                  c.name = r;
                                  c.email = r;
                                  this.currentUser.contacts.list.push(c);
                                  this.authSvc.updateUser(this.currentUser);
                                  this.saveContact(r);
                                }
                              }
                              // Move message sent to tour folder
                              if (this.component.state === 'PEND') {
                                const data2 = {
                                  'user_id': this.currentUser.id,
                                  'session_id': localStorage.getItem('session_id'),
                                  'folder_id': localStorage.getItem('folder_id'),
                                  'component_id': this.component.id,
                                  'OriginId': 'sentitems',
                                };
                                // First web_link is draft, then web_link changes to sent, gotta update it
                                this.moveOutlookMessage(data2, 0);
                                this.edFocus = false;
                              }
                            } else {
                              if (res1.message.includes('ErrorInvalidIdMalformed')) {
                                this.snackSvc.openSnackBar('Not in Outlook. Compose mail again');
                              } else {
                                this.snackSvc.resultsElse(res1);
                              }
                            }
                            this.loading = false;
                          },
                          err1 => {
                            this.loading = false;
                            console.log(err1);
                            this.component.sent_at = null;
                            this.component.state = null;
                            this.snackSvc.openSnackBar('Error sending message');
                          });
                    } else {
                      this.loading = false;
                      this.snackSvc.openSnackBar('Message already sent');
                    }
                  } else {
                    setTimeout(() => {
                      this.loading = false;
                      this.msg_loading = false;
                      this.att_loading = false;
                    }, 1);
                  }
                }
              );
          } else {
            this.loading = false;
            console.log('Component not updated in database');
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Sending message');
          this.loading = false;
        });
    this.component.web_link = this.activeTour.components[this.comIdx]['web_link'];
  }

  deleteAllMessages() {
    for (const m of this.messages) {
      this.deleteMessage(m.id, m.conversationId);
    }
  }

  cleanMessage(comIdx) {
    this.component.message_id = null;
    this.component.conversation_id = null;
    this.component.html_body = null;
    this.component.web_link = null;
    this.component.sent_at = null;
    this.component.state = null;
    this.activeTour.components[comIdx]['message_id'] = this.component.message_id;
    this.activeTour.components[comIdx]['conversation_id'] = this.component.conversation_id;
    this.activeTour.components[comIdx]['sent_at'] = this.component.sent_at;
    this.activeTour.components[comIdx]['web_link'] = this.component.web_link;
    this.activeTour.components[comIdx]['html_body'] = this.component.html_body;
  }

  onDeleteMessage(msg_id, conv_id) {
    this.msg_loading = true;
    this.att_loading = true;
    // Show snackbar to undo delete
    const txt = this.component.sent_at ? 'Delete message?' : 'Discard draft?';
    const snackbarRef = this.snackSvc.openSnackBar(txt, 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.deleteMessage(msg_id, conv_id);
        }
        setTimeout(() => {
          this.edFocus = false;
          this.msg_loading = false;
          this.att_loading = false;
        }, 1);
      });
  }

  deleteMessage(msg_id, conv_id) {
    if (this.beta && this.component.message_id === msg_id || this.component.message_id?.substring(0, 3) === 'pln') {
      this.component.message_id = null;
      this.component.html_body = null;
      this.component.sent_at = null;
      this.component.state = null;
      this.updateComponent('discard_draft');
      // Update fields in memory
      this.activeTour.components[this.comIdx]['message_id'] = this.component.message_id;
      this.activeTour.components[this.comIdx]['html_body'] = this.component.html_body;
      this.activeTour.components[this.comIdx]['sent_at'] = this.component.sent_at;
      this.activeTour.components[this.comIdx]['state'] = this.component.state;
      this.actTourSvc.setActiveTour(this.activeTour);
      this.messages.splice(this.messages.findIndex(it => '' + it.id === '' + msg_id), 1);
      this.msg_loading = false;
      this.att_loading = false;
      this.htmlEdited = false;
      return;
    }
    const data = {
      'user_id': this.currentUser.id,
      'session_id': localStorage.getItem('session_id'),
      'message_id': msg_id,
    };
    if (this.component.message_id === msg_id) {
      data['component_id'] = this.component.id;
    }
    this.outlookSvc.deleteMessage(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.messages.splice(this.messages.findIndex(it => '' + it.id === '' + msg_id), 1);
            if (this.messages.length > 0 && this.component.conversation_id) {
              this.outlookGetConvos();
            }
            if (this.component.message_id === msg_id) {
              this.cleanMessage(this.comIdx);
            }
            this.activeTour.message_list?.n_convos.splice(this.activeTour.message_list?.n_convos.indexOf(conv_id), 1);
            this.activeTour.message_list?.n_new_convos.splice(this.activeTour.message_list?.n_new_convos.indexOf(conv_id), 1);
            this.actTourSvc.setActiveTour(this.activeTour);
          } else {
            // If component or message not found remove from memory anyway
            if (res.status === 400) {
              this.cleanMessage(this.comIdx);
              this.actTourSvc.setActiveTour(this.activeTour);
              if (res.message.toString().includes('"code":"')) {
                const code = res.message?.toString().split('"code":"')[1].split('",')[0];
                if (code) {
                  this.snackSvc.openSnackBar('Error: ' + code);
                }
              }
            } else if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
            }
          }
          this.msg_loading = false;
          this.att_loading = false;
          this.htmlEdited = false;
        },
        error => {
          this.msg_loading = false;
          this.att_loading = false;
          this.snackSvc.openSnackBar('ERROR. Deleting message');
          this.getComponentInfo(this.component.id);
          console.log(error);
        });
  }

  onNewMessage(msg) {
    this.messages.map(item => item.folded = true);
    this.show_reply_box = true;
    this.reply_id = msg.id;
    if (!this.reply_recipients.includes(msg.toRecipients[0].emailAddress.address.toLowerCase())) {
      this.reply_recipients.push(msg.toRecipients[0].emailAddress.address.toLowerCase());
    }
    if (this.component.recipients && !this.reply_recipients.includes(this.component.recipients)) {
      this.reply_recipients = this.component.recipients.split(',');
    }
    setTimeout(() => {
      this.searchElementReplyBoxRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end'});
    }, 1);
  }

  onReplyMessage(msg) {
    this.messages.map(item => item.folded = true);
    this.show_reply_box = true;
    this.reply_id = msg.id;
    if (!this.reply_recipients.includes(msg.sender.emailAddress.address.toLowerCase())) {
      this.reply_recipients.push(msg.sender.emailAddress.address.toLowerCase());
    }
    if (this.component.recipients && !this.reply_recipients.includes(this.component.recipients)) {
      this.reply_recipients = this.component.recipients.split(',');
    }
    setTimeout(() => {
      this.searchElementReplyBoxRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end'});
    }, 1);
  }

  saveReplyDraft(ev) {
    localStorage.setItem('reply_drafts', JSON.stringify([{'id': this.component.id, 'msg': ev.target.value, 'reply_id': this.reply_id}]));
  }

  fileToBase64Array(file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      } catch (e) {
        reject(e);
      }
    });
  }

  async onAttachReplyMessage(files) {
    this.att_loading = true;
    for (const file of files) {
      const fileToUpload: File = file;
      const data = {
        'name': fileToUpload.name,
        'size': fileToUpload.size,
        'contentType': fileToUpload.type,
      };
      data['contentBytes'] = await this.fileToBase64Array(file);
      this.reply_attachs.push(data);
      if (fileToUpload.name.includes('FinalRoomingList') && !this.reply_msg.includes(this.find_att)) {
        this.reply_msg += this.find_att;
      }
    }
    this.att_loading = false;
    this.myInput2Variable.nativeElement.value = '';
  }

  removeReplyAttachment(at) {
    // console.log(this.reply_attachs);
    const tIdx = this.reply_attachs.findIndex(it => '' + it === '' + at);
    // console.log(tIdx);
    this.reply_attachs.splice(tIdx, 1);
    if (at.name.includes('FinalRoomingList')) {
      this.reply_msg = this.reply_msg.replace(this.find_att, '');
    }
    this.att_loading = false;
  }

  onAddSignature() {
    const template = TextTransformService.modifyTemplates(this.component, this.activeTour.tour, this.currentUser, this.currentUser.template);
    const signature = '\n' + template.signature;
    if (!this.reply_msg) {
      this.reply_msg = signature;
      return;
    }
    this.reply_msg = this.reply_msg.includes(signature)
      ? this.reply_msg.replace(signature, '')
      : this.reply_msg + signature;
  }

  onReplyScheduleSend() {
    if (this.reply_recipients && this.reply_recipients.length > 0) {
      let valid = true;
      for (let email of this.reply_recipients) {
        // Allow ",   " after first email
        email = email.trim();
        if (email) {
          valid = valid && this.validateEmail(email);
        }
      }
      if (!valid) {
        this.snackSvc.openSnackBar('Error. Recipients not valid [1]');
        this.loading = false;
        return;
      }
      this.component.reply_recs = this.reply_recipients.join(',');
    } else {
      this.snackSvc.openSnackBar('Error. Recipients not valid [2]');
      return;
    }
    const dialogRef = this.dialog.open(ScheduleSendComponent, {
      ...this.getDialogWidths(),
      data: {
        'isMobile': this.isMobile,
        'isSafari': this.isSafari,
        'timezone': this.component.city.timezone,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'close') {
        this.reply_scheduled_send = result['scheduled_send'];
        this.onSendReplyMessage();
      } else {
        // console.log('Didnt schedule reply email');
      }
    });
  }

  onSendReplyMessage() {
    this.loading = true;
    if (this.reply_recipients && this.reply_recipients.length > 0) {
      let valid = true;
      for (let email of this.reply_recipients) {
        // Allow ",   " after first email
        email = email.trim();
        if (email) {
          valid = valid && this.validateEmail(email);
        }
      }
      if (!valid) {
        this.snackSvc.openSnackBar('Error. Recipients not valid');
        this.loading = false;
        return;
      }
      this.component.reply_recs = this.reply_recipients.join(',');
    } else {
      this.snackSvc.openSnackBar('Error. No recipients');
      this.loading = false;
      return;
    }
    if (this.reply_recs_cc && this.reply_recs_cc.length > 0) {
      let valid = true;
      for (let email of this.reply_recs_cc) {
        // Allow ",   " after first email
        email = email.trim();
        if (email) {
          valid = valid && this.validateEmail(email);
        }
      }
      if (!valid) {
        this.snackSvc.openSnackBar('Error. CC recipients not valid');
        this.loading = false;
        return;
      }
      this.component.reply_ccs = this.reply_recs_cc.join(',');
    }
    if (!this.reply_msg) {
      this.snackSvc.openSnackBar('Error. Body is empty');
      this.loading = false;
      return;
    }
    // Show snackbar to undo Send
    const snackbarRef = this.snackSvc.openSnackBar('Sending message...', 'Undo', this.currentUser.user_settings.secs_undo);
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        if (!reason.dismissedByAction) {
          const params = {
            'user_id': this.currentUser.id,
            'session_id': localStorage.getItem('session_id'),
            'component_id': this.component.id,
            'reply_message_id': this.reply_id,
            'reply_content': this.reply_msg,
            'reply_recipients': this.component.reply_recs,
            'reply_recs_cc': this.component.reply_ccs,
            'files': this.reply_attachs,
          };
          if (this.reply_scheduled_send) {
            params['reply_scheduled_send'] = this.reply_scheduled_send;
          }
          // console.log(params);
          this.outlookSvc.replyMessage(params)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              res => {
                console.log(res);
                if (res.status < 400) {
                  this.show_reply_box = false;
                  this.reply_attachs = [];
                  this.reply_msg = null;
                  const drafts = JSON.parse(localStorage.getItem('reply_drafts') || '[]');
                  const updatedDrafts = drafts.filter((draft: { id: number }) => draft.id !== this.component.id);
                  localStorage.setItem('reply_drafts', JSON.stringify(updatedDrafts));
                  setTimeout(() => {
                    this.outlookGetConvos();
                  }, 1000);
                  if (this.reply_scheduled_send) {
                    this.reply_scheduled_send = null;
                    this.snackSvc.openSnackBar('Message scheduled');
                  } else {
                    this.snackSvc.openSnackBar('Message sent');
                  }
                } else {
                  this.snackSvc.resultsElse(res);
                }
                this.loading = false;
              },
              err => {
                console.log(err);
                this.loading = false;
                this.snackSvc.openSnackBar('Error sending message');
              });
        } else {
          setTimeout(() => {
            this.loading = false;
          }, 1);
        }
      });
  }

  onDiscardReply() {
    // msg.folded = true;
    const snackbarRef = this.snackSvc.openSnackBar('Discard draft?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          setTimeout(() => {
            // this.messages.map(item => item.folded = !(item.id === this.reply_id));
            this.show_reply_box = false;
            this.reply_id = null;
            this.reply_msg = null;
            const drafts = JSON.parse(localStorage.getItem('reply_drafts') || '[]');
            const updatedDrafts = drafts.filter((draft: { id: number }) => draft.id !== this.component.id);
            localStorage.setItem('reply_drafts', JSON.stringify(updatedDrafts));
          }, 1);
        }
      });
  }

  // Get Users contact list
  async saveContact(email) {
    const data: FormData = new FormData();
    data.append('session_id', localStorage.getItem('session_id'));
    data.append('email', email);
    const res = await this.outlookSvc.saveContact(data);
    console.log(res);
    if (res.status < 400) {
      this.currentUser.contacts.list.push(new Contact(res.results.contact));
      this.authSvc.updateUser(this.currentUser);
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  // OTHER

  toggleDietary() {
    this.showDiet = !this.showDiet;
    if (this.showDiet) {
      if (this.component.requests) {
        if (!this.component.requests.includes('{di}')) {
          this.component.requests += '{di}';
        }
      } else {
        this.component.requests = '{di}';
      }
    } else {
      this.component.requests = this.component.requests?.replace(/{di}/g, '');
    }
  }

  toggleConfirm() {
    if (this.hotel && !this.component?.itinerary?.hotel?.confirmed) {
      this.editBasicComp = true;
      this.snackSvc.openSnackBar('Select a hotel, or add new');
      return;
    }
    this.conf_load = true;
    let conf = null;
    if (!this.component.confirmed_at) {
      conf = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'UTC');
    }
    const data = {
      'confirmed_at': conf,
    };
    // console.log(data);
    this.httpCompSvc.updateComponent(this.component.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.conf_load = false;
          console.log(res);
          if (res.status < 400) {
            this.component.confirmed_at = res.results.component.confirmed_at;
            this.component.updated_at = res.results.component.updated_at;
            this.activeTour.components[this.comIdx]['confirmed_at'] = this.component.confirmed_at;
            this.activeTour.components[this.comIdx]['updated_at'] = this.component.updated_at;
            this.actTourSvc.setActiveTour(this.activeTour);
          } else {
            this.snackSvc.openSnackBar('Error confirming. Try again');
          }
        },
        error => {
          this.conf_load = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating confirmation');
        });
  }

  onNewOpt(idx?) {
    if (idx) {
      this.createTourOptional(idx);
    } else {
      this.onAddNewOpt();
    }
  }

  // Add new optional
  onAddNewOpt() {
    const dialogRef = this.dialog.open(CreateOptionalComponent, {
      minWidth: '80vw',
      maxWidth: '90vw',
      autoFocus: true,
      data: {
        'req_id': this.currentUser.id,
        'city_id': this.component.city_id,
        'company_id': this.activeTour.tour.company_id,
        'public_notes': this.component.events[0]?.public_notes,
        'confirm_notes': this.component.confirm_notes,
        'name': this.component.name,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        this.createTourOptional(result['optional']);
      }
    });
  }

  createTourOptional(opt) {
    this.loading = true;
    const data = {
      'tour_id': this.activeTour.tour.id,
      'component_id': this.component.id,
      'optional_id': opt.id,
    };
    // console.log(data);
    this.httpTOPSvc.createTourOptional(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.component.name = res.results.component.name;
            this.component.supplier = res.results.component.supplier;
            this.component.image = res.results.component.image;
            this.component.description = res.results.component.description;
            this.component.tour_optional_id = res.results.tour_optional.id;
            this.onManageOptional(this.component.tour_optional_id);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  onManageOptional(id) {
    if (id) {
      this.router.navigate(['tour', this.activeTour.tour.prodid, 'optionals', id], {queryParams: {returnUrl: window.location.pathname}});
    }
  }

  onManageHotel(id) {
    if (this.hotel && !this.component?.itinerary?.hotel?.confirmed) {
      this.editBasicComp = true;
      this.snackSvc.openSnackBar('Select a hotel, or add new');
      return;
    }
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'hotels', id], {
      queryParams: {
        returnUrl: window.location.pathname,
        i: this.component.itinerary_id
      }
    });
  }

  setAllFalse() {
    this.hotel = false;
    this.on_hotel = false;
    this.restaurant = false;
    this.guss = false;
    this.transfer = false;
    this.arrivals = false;
    this.departures = false;
    this.gussbus = false;
    this.activity = false;
    this.orient_walk = false;
    this.freetime = false;
    this.optional = false;
    this.td_optional = false;
    this.info = false;
    this.other = false;
    this.headsets = false;
    this.tl_private = false;
    this.safety = false;
    this.incident = false;
    this.birthday = false;
  }

  changeDS() {
    // These reqs are added to
    this.d_nightsRequests = null;
    if (this.driverSleeps) {
      let driver_nights = ',';
      this.Dnights.forEach((v, i) => {
        // Go over checkboxes to see if checked is true
        if (v.checked) {
          driver_nights += i + ',';
        }
      });
      this.d_nightsRequests = driver_nights;
      if (Object.values(this.Dnights).every(item => item.checked === false)) {
        this.d_nightsRequests = null;
        this.numberStaff = this.component.npax ? +this.component.npax.split(' + ')[1] : 1;
      } else {
        this.numberStaff = this.component.npax ? +this.component.npax.split(' + ')[1] + 1 : 2;
      }
    }
  }

  toggleShowAddress(txt) {
    if (!this.component.requests) {
      this.component.requests = '';
    }
    if (txt === 'from') {
      this.showFromAddress = !this.showFromAddress;
      this.component.requests = this.component.requests.replace(/\{ha_from}/g, '');
      this.component.requests = this.showFromAddress ? '{ha_from}' + this.component.requests : this.component.requests;
    } else {
      this.showToAddress = !this.showToAddress;
      this.component.requests = this.component.requests.replace(/\{ha_to}/g, '');
      this.component.requests = this.showToAddress ? this.component.requests + '{ha_to}' : this.component.requests;
    }
  }

  changeTrsfP() {
    this.component.npax = this.numberPax + ' + ' + this.numberStaff;
  }

  onChangeCheckbox(e, ns) {
    // Find date in array and change status
    const itemIndex = this.Dnights.findIndex(item => '' + item.day === '' + ns.day);
    this.Dnights[itemIndex].checked = e.checked;
    this.changeDS();
  }

  removeEFWarn(html) {
    const regex = new RegExp('<div style="background-color:#FFEB9C.*[\\s\\S]*@ef.com</b></div>', 'gm');
    html = html.replace(regex, '');
    return html;
  }

  removeEFWarnPre(html) {
    const regex = new RegExp('CAUTION: This email.*security@ef.com', 'gm');
    html = html.replace(regex, '');
    return html;
  }

  validateInput(txt?) {
    if (!this.component.comp_type_id) {
      return 'Error. Component type needed';
    }
    if (this.component.city_id <= 0) {
      return 'Error. City needed';
    }
    if (!this.component.start_date) {
      return 'Error. Start date needed';
    }
    if (this.component.end_date && (this.component.end_date < this.component.start_date)) {
      return 'Error. Invalid dates';
    }
    if (!this.component.end_date) {
      this.component.end_date = this.component.start_date;
      this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
    }
    if (txt && txt === 'msg') {
      if (!this.component.name && this.component.mailable) {
        this.editBasicComp = true;
        setTimeout(() => {
          this.compNameElementSubjectRef.nativeElement.focus();
        }, 10);
        return 'Error. Component name needed';
      }
    }
    if (!this.component.from && (this.transfer && this.component.mailable)) {
      setTimeout(() => {
        this.fromElementSubjectRef.nativeElement.focus();
      }, 10);
      return 'Error. "From" field needed';
    }
    if (!this.component.to && (this.transfer && this.component.mailable)) {
      setTimeout(() => {
        this.toElementSubjectRef.nativeElement.focus();
      }, 10);
      return 'Error. "To" field needed';
    }
    if (!this.component.to && this.safety) {
      return 'Error. Timezone needed';
    }
    if (this.safety && this.component.to && !Object.values(this.timezones).includes(this.component.to)) {
      return 'Error. Wrong timezone';
    }
    if (this.component.recipients) {
      const list = this.component.recipients.split(',');
      let valid = true;
      for (let email of list) {
        // Allow ",   " after first email
        email = email.trim();
        if (email) {
          valid = valid && this.validateEmail(email);
        }
      }
      if (!valid) {
        return 'Error. Recipients not valid';
      }
    }
    if (this.component.recs_cc) {
      const list = this.component.recs_cc.split(',');
      let valid = true;
      for (let email of list) {
        // Allow ",   " after first email
        email = email.trim();
        if (email) {
          valid = valid && this.validateEmail(email);
        }
      }
      if (!valid) {
        return 'Error. CC recipients not valid';
      }
    }
    if (this.component.recs_bcc) {
      const list = this.component.recs_bcc.split(',');
      let valid = true;
      for (let email of list) {
        // Allow ",   " after first email
        email = email.trim();
        if (email) {
          valid = valid && this.validateEmail(email);
        }
      }
      if (!valid) {
        return 'Error. BCC Recipients not valid';
      }
    }
  }

  updateTour(txt) {
    if (txt === 'td_overnight') {
      this.activeTour.tour.td_overnight = this.overNight ? 1 : 0;
    } else if (txt === 'dietary_issues') {
      this.loading = true;
      this.activeTour.tour.dietary_issues = this.dietaryIssues;
    }
    const data = {
      'req_id': this.currentUser.id,
    };
    data[txt] = this.activeTour.tour[txt];
    this.httpTour.updateTour(this.activeTour.tour.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status === 203) {
          this.activeTour.tour[txt] = res.results.tour[txt];
          this.actTourSvc.setActiveTour(this.activeTour);
        } else {
          this.snackSvc.resultsElse(res);
        }
        this.loading = false;
      }, error => {
        this.loading = false;
        this.snackSvc.openSnackBar('ERROR. Updating tour');
        console.log(error);
      });
  }

  onAddEndTime(ev) {
    if (ev.start_time) {
      const [startHour, startMinute] = ev.start_time.split(':').map(Number);
      const endTime = new Date();
      endTime.setHours(startHour + 1, startMinute);
      ev.end_time = endTime.toLocaleTimeString(['es-ES'], {hour: '2-digit', minute: '2-digit'});
    }
  }

  onToggleBFbox(ev) {
    if (this.component.sent_at) {
      return;
    }
    if (ev.private_notes?.includes('BFB')) {
      ev.private_notes = ev.private_notes.replace(/BFB/g, '');
      ev.private_notes = ev.private_notes ? ev.private_notes : null;
    } else {
      ev.private_notes = ev.private_notes ? ev.private_notes + 'BFB' : 'BFB';
    }
  }

  validURL(str) {
    const pattern = new RegExp(
      '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})', 'i');
    return !str || !!pattern.test(str);
  }

  checkUrl(event, type?: string): boolean {
    if (!this.validURL(event['url'])) {
      if (type !== 'silent') {
        this.snackSvc.openSnackBar('ERROR. Not a valid URL');
      }
      return false;
    }
    if (event['url'] && event['url'].includes('youtube.com')) {
      event['url'] = event['url'].replace('http://', '');
      if (!event['url'].includes('https://', 0)) {
        event['url'] = 'https://' + event['url'];
      }
    }
    if (event['url'] && event['url'].includes('youtu.be/')) {
      const videoId = event['url'].split('.be/')[1];
      event['url'] = 'https://youtube.com/watch?v=' + videoId;
    }
    return true;
  }

  onDefaultTime() {
    if (this.currentUser.user_settings?.safety_check?.includes(':')) {
      this.component.events[0].start_time = this.currentUser.user_settings.safety_check;
      const tmp = this.component.events[0].start_time.split(':');
      this.sc_hr = tmp[0];
      this.sc_min = tmp[1];
      this.updateEvents('start_time', true);
    }
  }

  onChangedTime(txt, ev) {
    const tmp = ev.start_time?.split(':') || ['', ''];
    if (txt === 'h') {
      ev.start_time = this.sc_hr + ':' + tmp[1];
    } else if (txt === 'm') {
      ev.start_time = tmp[0] + ':' + this.sc_min;
    }
  }

  updateEvents(field: string, save?: boolean) {
    for (const key in this.component.events) {
      if (this.component.events.hasOwnProperty(key)) {
        const event = this.component.events[key];
        if (!this.checkUrl(event)) {
          this.msg_loading = false;
          this.att_loading = false;
          return;
        }
        if (event.start_time && !/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(event.start_time)) {
          this.snackSvc.openSnackBar('Start time must be 24h format');
          this.changeTime({target: {value: event.start_time}}, event, 'start');
          this.msg_loading = false;
          this.att_loading = false;
          return;
        }
        if (event.end_time && !/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(event.end_time)) {
          this.snackSvc.openSnackBar('End time must be 24h format');
          this.changeTime({target: {value: event.end_time}}, event, 'end');
          this.msg_loading = false;
          this.att_loading = false;
          return;
        }
        // If short url form
        if (event?.url?.includes('https://forms.gle/')) {
          this.snackSvc.openSnackBar('Error. Use long Google form URL ');
          this.msg_loading = false;
          this.att_loading = false;
          return;
        }
        // If component date not first event's day date, change event day_id
        if (this.component.start_date !== this.component.events[0]?.day?.date) {
          const idx = this.activeTour.days.findIndex(d => d.date === this.component.start_date);
          if (key === '0' && idx >= 0) {
            event.day_id = this.activeTour.days[idx].id;
          }
        }
        event.name = this.component.name;
        // Public notes create links if fit
        let pub_notes = event.public_notes ? event.public_notes.replace(/<a href=/g, '<a target="_blank" href=') : null;
        if (pub_notes) {
          const urlRegex = /(?<!href="|src=")(https?:\/\/[^\s<]+)/g;
          const additionalCharsPerUrl = 32;
          let totalAddedChars = 0;
          const matches = pub_notes.match(urlRegex) || [];
          matches.forEach(url => {
            totalAddedChars += additionalCharsPerUrl + url.length;
          });
          if (pub_notes.length + totalAddedChars <= 2048) {
            pub_notes = pub_notes.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
          }
        }
        let data = {};
        if (field === 'full') {
          if (this.hotel || this.on_hotel) {
            if (key === '0') {
              event.name = 'Check-in ' + (this.component.supplier ? this.component.supplier : this.component.city_name);
            } else {
              event.name = 'Breakfast in ' + (this.component.supplier ? this.component.supplier : this.component.city_name);
            }
          } else if (this.freetime) {
            event.name = 'Free time ' + this.component.city_name;
          }
          data = {
            'req_id': this.currentUser.id,
            'day_id': event.day_id,
            'name': event.name,
            'start_time': event.start_time,
            'end_time': event.end_time,
            'meet_place': event.meet_place,
            'meet_time': event.meet_time,
            'url': event.url,
            'private_notes': event.private_notes,
            'public_notes': pub_notes,
            'visible': event.visible,
          };
          if (this.checkUrl(event, 'silent')) {
            data['url'] = this.hotel ? this.component.events[0].url : event.url;
          }
        } else {
          data['req_id'] = this.currentUser.id;
          data[field] = event[field];
        }
        // console.log(data);
        this.httpItin.updateEvent(event.id, data)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(res => {
            console.log(res);
            if (res.status === 203) {
              const itemIndex = this.component.events.findIndex(ev => '' + ev.id === '' + event.id);
              const evIdx = this.activeTour.events.findIndex(i => '' + i.id === '' + res.results.event.id);
              const res_event = res.results.event;
              res_event.showendtime = res_event.end_time !== null;
              // tslint:disable-next-line:no-unused-expression
              !this.checkUrl(res_event, 'silent') ? res_event.url = event.url : null;
              this.component.events[itemIndex] = res_event;
              this.activeTour.events[evIdx] = res_event;
              this.actTourSvc.setActiveTour(this.activeTour);
              if (this.changedType && '' + key === '' + (this.component.events.length - 1)) {
                this.getComponentInfo(this.component.id);
              }
              this.changedType = false;
              // Display extras
              const idx = this.component.events.findIndex(ev => ev.public_notes || ev.meet_place || ev.url);
              this.event_day_notes = this.event_day_notes ? this.event_day_notes : (idx >= 0 ? idx : 0);
              this.showPublicNotes = idx >= 0 || this.currentUser.id === 334; // Georgios Zach
              this.showPrivateNotes = !!this.component.confirm_notes;
              this.show_act_checkin = !!this.component.events[0].meet_time;
              this.showHExtra = !!this.component.hotel_extras || !!this.show_act_checkin;
              this.showDiet = this.component.requests?.includes('{di}');
              this.component.start_day = this.datePipe.transform(this.component.start_date, 'EEEE');
              this.component.end_day = this.datePipe.transform(this.component.end_date, 'EEEE');
              // Warn about color styling
              if (this.component.events[itemIndex].public_notes?.includes('style="color:')) {
                this.component.events[itemIndex].warning_txt = 'Warning: applied color may break app\'s dark mode';
              }
              if (save) {
                this.msg_loading = false;
                this.att_loading = false;
                this.edFocus = false;
                if (this.component.message_id && !this.component.mailable) {
                  this.deleteMessage(this.component.message_id, this.component.conversation_id);
                }
                return;
              }
              // Compose message on the last event
              if (this.component.mailable) {
                if (key === '' + (this.component.events.length - 1)) {
                  // If tour has been paid
                  if (this.activeTour.tour.tour_settings.paid_at) {
                    // If not beta (planafy)
                    if (!this.beta) {
                      if (this.component.message_id && this.component.message_id.substring(0, 3) !== 'pln') {
                        // Tour paid, message exists: update
                        this.outlookUpdateMessage();
                      } else {
                        // Tour paid, message not exists: create
                        this.outlookWriteMessage();
                      }
                    } else {
                      // Beta: fake message
                      this.fakePreviewMessage();
                    }
                  } else {
                    // Tour not paid: fake message
                    this.fakePreviewMessage();
                  }
                }
                if (!this.component.name) {
                  this.editBasicComp = true;
                  this.snackSvc.openSnackBar('No component name given');
                }
              } else {
                this.msg_loading = false;
                this.att_loading = false;
                this.snackSvc.openSnackBar('ERROR. Component not mailable');
              }
            } else {
              this.msg_loading = false;
              this.att_loading = false;
              this.snackSvc.resultsElse(res);
            }
          }, error => {
            console.log(error);
            this.msg_loading = false;
            this.att_loading = false;
            this.snackSvc.openSnackBar('ERROR. Updating events');
          });
      }
    }
    if (this.hotel && !this.component.itinerary.hotel_id) {
      this.editBasicComp = true;
      this.snackSvc.openSnackBar('Warning. Select hotel');
    }
  }

  openDirections() {
    let from = this.component?.from?.replace('/[\s&?]+/', '+');
    let to = this.component?.to?.replace('/[\s&?]+/', '+');
    if (this.component.requests?.includes('ha_from')) {
      from = from + ',' + this.component?.itinerary?.hotel.address?.replace('/[\s&?]+/', '+');
    }
    if (this.component.requests?.includes('ha_to')) {
      to = to + ',' + this.component?.itinerary?.hotel?.address?.replace('/[\s&?]+/', '+');
    }
    const url = 'https://www.google.com/maps?f=d&dirflg=d&saddr=' + from + '&daddr=' + to;
    this.openExternalLink(url);
  }

  openExternalLink(url) {
    window.open(url, '_blank');
  }

  onChangeEvt(ev) {
    this.showPublicNotes = true;
    this.event_day_notes = this.component.events.findIndex(e => '' + e.id === '' + ev.id);
    setTimeout(() => {
      this.searchElementPublicNotesRef.nativeElement.scrollIntoView({behavior: 'auto', block: 'center'});
    }, 1);
  }

  goToComp(comp) {
    this.router.navigate(['tour/' + this.activeTour.tour.prodid + '/day/' + (comp.day_id ? comp.day_id : this.day_id) + '/component/' + comp.id]);
    this.getComponentInfo(comp.id);
  }

  prevComp() {
    if (this.comIdx > 0) {
      const prev_comp_id = this.activeTour.components[this.comIdx - 1].id;
      this.comIdx = this.comIdx - 1;
      this.router.navigate(['tour/' + this.activeTour.tour.prodid + '/day/' + this.day_id + '/component/' + prev_comp_id]);
      this.getComponentInfo(prev_comp_id);
    }
  }

  nextComp() {
    if (this.comIdx < this.activeTour.components.length - 1) {
      const next_comp_id = this.activeTour.components[this.comIdx + 1].id;
      this.comIdx = this.comIdx + 1;
      this.router.navigate(['tour/' + this.activeTour.tour.prodid + '/day/' + this.day_id + '/component/' + next_comp_id]);
      this.getComponentInfo(next_comp_id);
    }
  }

  goComp(c_id) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['admin', 'comps'], {queryParams: {c: c_id}}));
    window.open(url, '_blank');
  }

  onClickSubject() {
    setTimeout(() => {
      this.searchElementSubjectRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 1);
  }

  isToday(date) {
    const today = new Date();
    const someDate = new Date(date);
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
