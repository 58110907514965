import {Component, Inject} from '@angular/core';
import {Activetour} from '../../../../shared/models/activetour.model';
import {Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {takeUntil} from 'rxjs/operators';
import {Comptype} from '../../../../shared/models/comptype.model';
import {Company} from '../../../../shared/models/company.model';

@Component({
  selector: 'app-add-act',
  templateUrl: './add-act.component.html',
  styleUrls: ['./add-act.component.scss']
})
export class AddActComponent {
  activeTour: Activetour;
  compTypes: Comptype[];
  companies: Company[] = [];
  cities: any[] = [];
  city_id: string;
  city_name: string;
  comp_type: string;
  loading = false;
  showCias = false;

  private onDestroy$ = new Subject<boolean>();

  profileForm = new UntypedFormGroup({
    reason: new UntypedFormControl(''),
    comp_type_id: new UntypedFormControl('', Validators.required),
    city_id: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', Validators.required),
    image_id: new UntypedFormControl(''),
    from: new UntypedFormControl(''),
    to: new UntypedFormControl(''),
    supplier: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    notes: new UntypedFormControl(''),
    when: new UntypedFormControl(''),
    prev_city: new UntypedFormControl(''),
    next_city: new UntypedFormControl(''),
    companies: new UntypedFormControl(''),
  });

  constructor(public dialogRef: MatDialogRef<AddActComponent>,
              private snackSvc: SnackbarService,
              private httpCitySvc: HttpCityService,
              private activeTourSvc: ActivetourService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.compTypes = JSON.parse(localStorage.getItem('compTypes') || '[]');
    this.companies = JSON.parse(localStorage.getItem('companies') || '[]');
    const list = '.2.3.4.5.6.8.10.12.13.14.15.18.19.21.';
    this.compTypes = this.compTypes.filter(c => list.includes('.' + c.id + '.'));
    const list2 = '.1.2.3.4.5.';
    this.companies = this.companies.filter(c => list2.includes('.' + c.id + '.'));
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.getAllCities();
  }

  selectComp(ev) {
    this.comp_type = this.compTypes.find(ct => ct.id === ev.value).description;
  }

  submit(): void {
    if (this.profileForm.controls.name.invalid) {
      this.snackSvc.openSnackBar('Invalid name');
      return;
    }
    if (this.profileForm.controls.comp_type_id.invalid) {
      this.snackSvc.openSnackBar('Invalid comp type');
      return;
    }
    if (this.profileForm.controls.city_id.invalid) {
      this.snackSvc.openSnackBar('Invalid city');
      return;
    }
    this.close('send');
  }

  getAllCities() {
    this.loading = true;
    this.httpCitySvc.getCitiesBasic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.loading = false;
          console.log(res);
          if (res.status === 200) {
            // Insert all cities
            this.cities = res.results.cities;
            if (this.data.city_id) {
              this.profileForm.controls['city_id'].setValue(this.data.city_id);
              this.city_name = this.cities.find(city => city.id === this.data.city_id).name;
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all cities');
        });
  }

  onSelectCity(e) {
    this.city_id = e.target.value;
  }

  close(reason) {
    this.profileForm.value.reason = reason;
    this.dialogRef.close(this.profileForm.value);
  }
}
