import {Component, OnDestroy, OnInit} from '@angular/core';
import {TourPax} from '../../../../../shared/models/tour-pax.model';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {User} from '../../../../../shared/models/user.model';
import {environment} from '../../../../../../environments/environment';
import {HttpUserService} from '../../../../../shared/services/http/http-user.service';
import {TourOptional} from '../../../../../shared/models/tour-optional.model';
import {HttpTourOptionalService} from '../../../../../shared/services/http/http-tour-optional.service';
import {TourOptPax} from '../../../../../shared/models/tour-opt-pax.model';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AddPaxComponent} from '../../../../components/tour/add-pax/add-pax.component';
import {EditPaxComponent} from '../../../../components/tour/edit-pax/edit-pax.component';
import {AddUpgRoomComponent} from '../../../../components/hotel/add-upg-room/add-upg-room.component';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';
import {TourGroup} from '../../../../../shared/models/tour-group.model';

@Component({
  selector: 'app-tour-pax',
  templateUrl: './tour-pax.component.html',
  styleUrls: ['./tour-pax.component.scss']
})
export class TourPaxComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  appUrl: string;
  returnUrl: string;
  linkURL: string;
  copyText: string;
  menuTOOpen: boolean;
  et = false;
  plus = false;
  errpax = false;

  singles: number;
  doubles: number;
  twins: number;
  triples: number;
  quads: number;
  male: number;
  female: number;

  loadingPAX = false;
  tourPaxLoaded = false;
  loadingTOP = false;
  ok_copied1 = false;
  ok_copied2 = false;
  showHidden = false;
  showInactive = false;
  tAdms: number;

  sortBy: string;
  sortNameAsc = true;
  sortDateAsc = true;

  tourPax: TourPax[];
  tourPaxActive: TourPax[];
  filTourPaxActive: TourPax[] = [];
  tourPaxBlock: TourPax[];
  tourPaxHidden: TourPax[];
  tourPaxFake: TourPax[];
  filTourPaxFake: TourPax[] = [];
  tourOptionals: TourOptional[] = [];
  tourRooms: any[] = [];
  tourGenders: any[] = [];
  filterTG: any;
  filterRT: any;
  filterUG: any;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private titleSvc: TitleService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private versioning: VersioningService,
    private httpTour: HttpTourService,
    private httpTOSvc: HttpTourOptionalService,
    private httpUser: HttpUserService,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.et = tour?.tour.company_id === 3;
        this.plus = tour?.tour.code.substring(0, 1) === 'X';
      });
  }

  ngOnInit() {
    this.appUrl = environment.appUrl;
    const prod_id = this.route.snapshot.params['tour-prodid'];
    const toursUser = this.activeTourSvc.getToursUser();
    // If user has no tours or from tours none is the prodid
    if (!toursUser || !toursUser.find(obj => '' + obj.prodid === '' + prod_id)) {
      this.router.navigate(['tours']);
      this.snackSvc.openSnackBar('Error. Not your tour?');
      return;
    }
    if (!this.activeTour) {
      const tour = toursUser.find(obj => '' + obj.prodid === '' + prod_id);
      const activeTour = new Activetour();
      activeTour.tour = tour;
      this.activeTourSvc.setActiveTour(activeTour);
    }
    this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
    if (this.activeTour.tour.prodid + '' !== prod_id) {
      this.snackSvc.openSnackBar('Error. Wrong tour?');
      this.router.navigate(['tours']);
      return;
    }
    if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
      this.snackSvc.openSnackBar('Error. Not your tour?');
      this.router.navigate(['tours']);
      return;
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    const text0 = '*' + this.activeTour.tour.name + '*\n';
    const text1 = 'Follow the tour itinerary with the Planafy app!\n\n';
    const text2 = '\n1) Create account: Quick sign-up or register\n' +
      '2) At tour invite page click JOIN\n' +
      '3) Choose app store and download free app\n\n' +
      'Or download app, sign-up and use tour password: ' + this.activeTour.tour.prodid;
    this.linkURL = this.appUrl + 'join/' + btoa('' + this.activeTour.tour.id).replace(/=/g, '');
    this.copyText = text0 + text1 + this.linkURL + text2;
    this.sortBy = 'name';
    this.getTourPax(this.activeTour.tour.id);
  }

  processLists() {
    this.tourPax.map(tp => {
      if (!tp.user_id) {
        tp.user = new User();
        tp.user.name = tp.tp_name;
        tp.user.gender = tp.tp_gender;
        tp.user.role = 1;
      }
    });
    this.tourPax.sort((a, b) => {
      const nameA = a.user.name.toLowerCase() || '';
      const nameB = b.user.name.toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
    const uniqueGenders = [
      ...new Set(this.tourPax.filter(tp => tp.active && !tp.hidden).map(tp => tp.user.gender))
    ];
    const genderOrder = ['female', 'male', 'x'];
    uniqueGenders.sort((a, b) => {
      return genderOrder.indexOf(a) - genderOrder.indexOf(b);
    });
    this.tourGenders = uniqueGenders.filter(g => !!g).map((gender, idx) => ({
      name: gender.substring(0, 1).toUpperCase(),
    }));
    const uniqueRooms = [
      ...new Set(this.tourPax.filter(tp => tp.active && !tp.hidden).map(tp => tp.room))
    ];
    const roomOrder = ['St', 'Si', 'Tw', 'Db', 'Tr', 'Qd'];
    uniqueRooms.sort((a, b) => {
      return roomOrder.indexOf(a) - roomOrder.indexOf(b);
    });
    this.tourRooms = uniqueRooms.map((room, idx) => ({
      name: room,
    }));
    this.tourPaxActive = this.tourPax.filter(tp => tp.active && !tp.hidden && tp.user_id);
    this.filTourPaxActive = this.tourPaxActive;
    this.tourPaxFake = this.tourPax.filter(tp => tp.active && !tp.user_id && !tp.hidden);
    this.filTourPaxFake = this.tourPaxFake;
    this.tourPaxHidden = this.tourPax.filter(tp => tp.active && tp.hidden);
    this.tourPaxBlock = this.tourPax.filter(tp => !tp.active && tp.user_id);
    if (this.filterTG) {
      this.filTourPaxActive = this.filTourPaxActive.filter(tp => tp.tourpax_group?.tour_group?.id === +this.filterTG.id);
      this.filTourPaxFake = this.filTourPaxFake.filter(tp => tp.tourpax_group?.tour_group?.id === +this.filterTG.id);
      this.tourPaxHidden = [];
      this.tourPaxBlock = [];
    }
    if (this.filterRT) {
      this.filTourPaxActive = this.filTourPaxActive.filter(tp => tp.room === this.filterRT);
      this.filTourPaxFake = this.filTourPaxFake.filter(tp => tp.room === this.filterRT);
      this.tourPaxHidden = [];
      this.tourPaxBlock = [];
    }
    if (this.filterUG) {
      const nameMap = {'F': 'female', 'M': 'male'};
      const filter = nameMap[this.filterUG] || 'x';
      this.filTourPaxActive = this.filTourPaxActive.filter(tp => tp.user.gender === filter);
      this.filTourPaxFake = this.filTourPaxFake.filter(tp => tp.user.gender === filter);
      this.tourPaxHidden = [];
      this.tourPaxBlock = [];
    }
    this.tAdms = this.filTourPaxActive.filter(tp => tp.user.role > 1).length;
    if (this.sortBy) {
      this.sortPax(this.sortBy);
    }
    this.updateSummary();
  }

  updateSummary() {
    this.singles = 0;
    this.doubles = 0;
    this.twins = 0;
    this.triples = 0;
    this.quads = 0;
    this.male = 0;
    this.female = 0;
    this.errpax = false;
    [...this.tourPaxActive, ...this.filTourPaxFake].forEach(t => {
      t.warn = '';
      if (!t.room) {
        t.warn = 'room';
        t.err = 'Room type needed';
        this.errpax = true;
      } else {
        if (t.room !== 'Si' && t.user.role > 1) {
          t.warn = 'room';
          t.err = 'Admins must have single room';
          this.errpax = true;
        } else if (this.plus && t.room === 'St') {
          t.warn = 'room';
          t.err = 'No St room in Plus';
          this.errpax = true;
        }
      }
      if (!t.user.gender) {
        t.warn += '-gender';
        if (t.warn.includes('room')) {
          t.err = 'Gender/Room needed';
        } else {
          t.err = 'Gender needed';
        }
      }
      t.last_txt = this.getLastTxt(t.updated_at);
      switch (t.room) {
        case 'Si':
          if (t.user.role < 2) {
            this.singles += 1;
          }
          break;
        case 'Db':
          this.doubles += 1;
          break;
        case 'Tw':
          this.twins += 1;
          break;
        case 'Tr':
          this.triples += 1;
          break;
        case 'Qd':
          this.quads += 1;
          break;
        case 'St':
          if (t.user.gender === 'female') {
            this.female += 1;
          } else if (t.user.gender === 'male') {
            this.male += 1;
          }
          break;
        default:
          break;
      }
    });
    this.tourPaxHidden.map(t => {
        t.last_txt = this.getLastTxt(t.updated_at);
      }
    );
    this.doubles = Math.ceil(this.doubles / 2);
    this.twins = Math.ceil(this.twins / 2);
    this.triples = Math.ceil(this.triples / 3);
    this.quads = Math.ceil(this.quads / 4);
  }

  filGen(res: any) {
    if (!res || this.filterUG === res.name) {
      this.filterUG = null;
    } else {
      this.filterUG = res.name;
    }
    this.processLists();
  }

  filRoom(res: any) {
    if (!res || this.filterRT === res.name) {
      this.filterRT = null;
    } else {
      this.filterRT = res.name;
    }
    this.processLists();
  }

  filGL(res: any) {
    if (!res || this.filterTG === res) {
      this.filterTG = null;
    } else {
      this.filterTG = res;
    }
    this.processLists();
  }

  sortPax(txt) {
    if (txt === 'name') {
      this.sortBy = txt;
      this.filTourPaxActive.sort((l, r) => {
        return l.user.name.toLowerCase() > r.user.name.toLowerCase() ? (this.sortNameAsc ? 1 : -1) :
          l.user.name.toLowerCase() < r.user.name.toLowerCase() ? (this.sortNameAsc ? -1 : 1) : 0;
      });
      this.filTourPaxFake.sort((l, r) => {
        return l.user.name.toLowerCase() > r.user.name.toLowerCase() ? (this.sortNameAsc ? 1 : -1) :
          l.user.name.toLowerCase() < r.user.name.toLowerCase() ? (this.sortNameAsc ? -1 : 1) : 0;
      });
    } else if (txt === 'last') {
      this.sortBy = txt;
      this.sortNameAsc = false;
      this.filTourPaxActive.sort((l, r) => {
        return l.updated_at > r.updated_at ? (this.sortDateAsc ? 1 : -1) : l.updated_at < r.updated_at ? (this.sortDateAsc ? -1 : 1) : 0;
      });
      this.filTourPaxFake.sort((l, r) => {
        return l.updated_at > r.updated_at ? (this.sortDateAsc ? 1 : -1) : l.updated_at < r.updated_at ? (this.sortDateAsc ? -1 : 1) : 0;
      });
    }
    this.filTourPaxActive.sort(
      (a, b) => a.user.role < b.user.role ? 1 : a.user.role === b.user.role ? 0 : -1
    );
    this.filTourPaxFake.sort(
      (a, b) => a.user.role < b.user.role ? 1 : a.user.role === b.user.role ? 0 : -1
    );
  }

  toggleSortPax(txt) {
    if (txt === 'name') {
      this.sortBy = txt;
      this.sortNameAsc = !this.sortNameAsc;
      this.filTourPaxActive.sort((l, r) => {
        return l.user.name.toLowerCase() > r.user.name.toLowerCase() ? (this.sortNameAsc ? 1 : -1) :
          l.user.name.toLowerCase() < r.user.name.toLowerCase() ? (this.sortNameAsc ? -1 : 1) : 0;
      });
      this.filTourPaxFake.sort((l, r) => {
        return l.user.name.toLowerCase() > r.user.name.toLowerCase() ? (this.sortNameAsc ? 1 : -1) :
          l.user.name.toLowerCase() < r.user.name.toLowerCase() ? (this.sortNameAsc ? -1 : 1) : 0;
      });
    } else if (txt === 'last') {
      this.sortBy = txt;
      this.sortNameAsc = false;
      this.sortDateAsc = !this.sortDateAsc;
      this.filTourPaxActive.sort((l, r) => {
        return l.updated_at > r.updated_at ? (this.sortDateAsc ? 1 : -1) : l.updated_at < r.updated_at ? (this.sortDateAsc ? -1 : 1) : 0;
      });
      this.filTourPaxFake.sort((l, r) => {
        return l.updated_at > r.updated_at ? (this.sortDateAsc ? 1 : -1) : l.updated_at < r.updated_at ? (this.sortDateAsc ? -1 : 1) : 0;
      });
    }
    this.filTourPaxActive.sort(
      (a, b) => a.user.role < b.user.role ? 1 : a.user.role === b.user.role ? 0 : -1
    );
    this.filTourPaxFake.sort(
      (a, b) => a.user.role < b.user.role ? 1 : a.user.role === b.user.role ? 0 : -1
    );
  }

  groupBy() {
    const dialogRef = this.dialog.open(AddUpgRoomComponent, {
      autoFocus: false,
      data: {'tour_pax': [...this.tourPaxActive, ...this.tourPaxFake], 'cia_id': this.activeTour.tour.company_id}
    });
    dialogRef.componentInstance.updateGroup.subscribe(result => {
      this.updateSummary();
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateSummary();
    });
  }

  getLastTxt(updated_at): string {
    let time_amount;
    let time_units;
    const startDate = new Date(updated_at);
    const endDate = new Date();
    const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 's';
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'm';
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'h';
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'd';
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'y';
    }
    return time_amount + time_units;
  }

  recentOn(upd) {
    const min = 4; // 3min
    return new Date(upd).getTime() + min * 60 * 1000 >= new Date().getTime();
  }

  /*
/* HTTP REQUESTS
*******************/
  getTourPax(id) {
    this.loadingPAX = true;
    this.filterTG = null;
    this.httpTour.getTourPax(id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.tourPaxLoaded = true;
            this.versioning.check(res.results.version);
            this.tourPax = res.results.tour_paxs;
            this.filterTG = null;
            this.filterRT = null;
            this.filterUG = null;
            this.processLists();
            this.tourOptionals = res.results.tour_optionals;
            this.activeTour.groups = res.results.tour_groups;
            this.activeTour.tour.tour_settings.visible = res.results.visible;
            if (!this.activeTour.tour.tour_settings.visible) {
              this.activeTour.tour.tour_settings.room_prefs = 'non';
            }
            this.activeTourSvc.setActiveTour(this.activeTour);
            // if empty list pop up
            if (this.tourPax.length <= 0) {
              this.onQuickAdd(1);
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingPAX = false;
        },
        error => {
          console.log(error);
          this.loadingPAX = false;
        });
  }

  onQuickAdd(tab?) {
    const dialogRef = this.dialog.open(AddPaxComponent, {
      minWidth: '40%',
      maxWidth: '100%',
      maxHeight: '90vh',
      data: {
        'user': this.currentUser,
        'activeTour': this.activeTour,
        'et': this.et,
        'tab': tab ?? null,
      }
    });
    dialogRef.componentInstance.roomUploaded.subscribe((res: any) => {
      this.tourPax = res.results.tour_paxs;
      this.activeTour.groups = res.results.tour_groups;
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.processLists();
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        this.onAddPAX(result['user'], result['room'] ? result['room'] : 'St', result['gl'] ?? result['gl']);
      }
    });
  }

  onAddPAX(user, room, gl) {
    this.loadingPAX = true;
    const data = {
      'tour_id': this.activeTour.tour.id,
      'room': room,
    };
    if (gl) {
      data['tour_group_id'] = gl.id;
    }
    if (user.id) {
      data['user_id'] = user.id;
    } else {
      if (user.name && user.gender) {
        data['tp_name'] = user.name;
        data['tp_gender'] = user.gender;
      }
    }
    // console.log(data);
    this.httpTour.createTourPax(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (!res.message.includes('already')) {
              this.loadingPAX = false;
              this.tourPax.push(res.results.tour_pax);
            } else {
              this.snackSvc.openSnackBar(res.message);
            }
            this.filterTG = null;
            this.processLists();
          } else {
            this.loadingPAX = false;
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.loadingPAX = false;
          this.snackSvc.openSnackBar('Error adding user to tour');
        });
  }

  onAddTourOptPAX(tpax: TourPax, to: TourOptional, paid?: string) {
    this.loadingTOP = true;
    const params = {
      'tour_pax_id': tpax.id,
      'tour_optional_id': to.id,
      'url': environment.selfUrl,
    };
    if (paid) {
      params['amount_paid'] = paid;
    }
    // console.log(params);
    this.httpTOSvc.createTourOptPax(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (!res.message.includes('already')) {
              tpax.tour_opt_pax.push(res.results.top);
            } else {
              this.deleteTourOptPax(res.results.top);
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTOP = false;
        },
        error => {
          console.log(error);
          this.loadingTOP = false;
        });
  }

  deleteTourOptPax(tourOptPax: TourOptPax) {
    this.httpTOSvc.deleteTourOptPax(tourOptPax.tour_pax_id, tourOptPax.tour_optional_id, 0)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.snackSvc.openSnackBar('Pax removed from optional');
            this.onRefreshTP();
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTOP = false;
        },
        error => {
          console.log(error);
          this.loadingTOP = false;
        });
  }

  TOPaid(to_id, top) {
    if (!top) {
      top = [];
    }
    let res = null;
    if (top.findIndex(it => it.tour_optional_id === to_id && it.paid_at) >= 0) {
      res = 'paid';
    } else if (top.findIndex(it => it.tour_optional_id === to_id) >= 0) {
      res = 'su';
    }
    return res;
  }

  markAsPaid(tpax: TourPax, to: TourOptional) {
    if (!to.price) {
      const snackbarRef = this.snackSvc.openSnackBar('Error. Provide public price', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.router.navigate(['tour', this.activeTour.tour.prodid, 'optionals', to.id], {queryParams: {returnUrl: window.location.pathname}});
          }
        });
      return;
    }
    // If mark as paid already signed up
    const topIDx = tpax.tour_opt_pax.findIndex(top => top.tour_optional_id === to.id);
    if (topIDx >= 0) {
      tpax.tour_opt_pax[topIDx].amount_paid = to.price;
      this.updateTourOptionalPax(tpax, tpax.tour_opt_pax[topIDx], 'paid_at');
    } else {
      // Sign up and also mark as paid
      this.onAddTourOptPAX(tpax, to, to.price);
    }
  }

  updateTourOptionalPax(tpax: TourPax, tourOptPax: TourOptPax, txt?) {
    this.loadingTOP = true;
    let data = {};
    if (txt === 'paid_at') {
      if (!tourOptPax.amount_paid || /^\s*$/.test(tourOptPax.amount_paid)) {
        data = {
          'amount_paid': null,
          'paid_at': null,
          'notes': null,
        };
      } else {
        data = {
          'amount_paid': tourOptPax.amount_paid,
          'notes': 'cash',
          'paid_at': this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es-ES'),
        };
      }
    } else if (txt === '!paid_at') {
      data = {
        'amount_paid': null,
        'paid_at': null,
        'notes': null,
        'delete': true,
      };
    } else if (txt === 'diff') {
      data = {
        'diff': tourOptPax.diff,
      };
    } else if (txt === 'notes') {
      data = {
        'notes': tourOptPax.notes,
      };
    }
    // console.log(data);
    this.httpTOSvc.updateTourOptionalPax(tourOptPax.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const idx = tpax.tour_opt_pax.findIndex(top => +top.id === +res.results.top.id);
            tpax.tour_opt_pax[idx] = res.results.top;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTOP = false;
        },
        error => {
          console.log(error);
          this.loadingTOP = false;
        });
  }

  // Set Active/Hidden
  onSetActive(tour_pax) {
    this.updateTourPax(tour_pax, [{'key': 'active', 'value': true}, {'key': 'hidden', 'value': false}]);
  }

  onSetInactive(tour_pax) {
    this.updateTourPax(tour_pax, [{'key': 'active', 'value': false}, {'key': 'hidden', 'value': false}]);
  }

  onSetHidden(tour_pax) {
    this.updateTourPax(tour_pax, [{'key': 'active', 'value': true}, {'key': 'hidden', 'value': true}]);
  }

  onSetVisible(tour_pax) {
    this.updateTourPax(tour_pax, [{'key': 'active', 'value': true}, {'key': 'hidden', 'value': false}]);
  }

  updateTourPax(tour_pax, values: any) {
    this.loadingPAX = true;
    const params = {
      'req_id': this.currentUser.id,
    };
    for (const i of values) {
      params[i.key] = i.value;
    }
    // console.log(params);
    this.httpTour.updateTourPax(tour_pax.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (res.results.tp.user.role > 1) {
              this.tAdms = this.tAdms - 1;
            }
            const idx = this.tourPax.findIndex(tp => +tp.id === +res.results.tp.id);
            this.tourPax[idx] = res.results.tp;
            this.processLists();
            this.loadingPAX = false;
          } else {
            this.loadingPAX = false;
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.loadingPAX = false;
          this.tourPax.push(tour_pax);
          this.processLists();
          console.log(error);
        });
  }

  // Open dialog
  onEditTourPAX(tp: TourPax) {
    const dialogRef = this.dialog.open(EditPaxComponent, {
      minWidth: '33%',
      maxWidth: '100%',
      maxHeight: '90vh',
      data: {
        'tourpax': tp,
        'req_id': this.currentUser.id,
        'cia_id': this.activeTour.tour.company_id,
        'groups': this.activeTour.groups
      }
    });
    dialogRef.componentInstance.updateTourpax.subscribe((tpax: TourPax) => {
      const tpidx = this.tourPax.findIndex(t => +t.id === +tpax.id);
      this.tourPax[tpidx] = tpax;
      this.processLists();
    });
    dialogRef.componentInstance.colorChanged.subscribe((res: any) => {
      this.tourPaxActive.forEach(tp_active => {
        if (tp_active?.tourpax_group?.tour_group?.id === res[0].id) {
          tp_active.tourpax_group.tour_group.color = res[1];
        }
      });
      this.tourPaxFake.forEach(tp_fake => {
        if (tp_fake?.tourpax_group?.tour_group?.id === res[0].id) {
          tp_fake.tourpax_group.tour_group.color = res[1];
        }
      });
    });
    dialogRef.componentInstance.deleteTourGroup.subscribe((res: any) => {
      this.tourPaxActive.map(tp_active => {
        if (tp_active.tourpax_group?.tour_group?.id === res.id) {
          tp_active.tourpax_group = null;
        }
      });
      this.tourPaxFake.map(tp_fake => {
        if (tp_fake.tourpax_group?.tour_group?.id === res.id) {
          tp_fake.tourpax_group = null;
        }
      });
    });
    dialogRef.componentInstance.deleteTP.subscribe((tpax: TourPax) => {
      this.onRemove(tpax);
    });
    dialogRef.componentInstance.add2TPGroup.subscribe((tg: TourGroup) => {
      this.onCreateTourpaxgroup(tp, tg);
    });
    dialogRef.componentInstance.updateTPGroup.subscribe((tpax: TourPax) => {
      if (tpax.tourpax_group.tour_group) {
        this.onUpdateTourpaxgroup(tpax);
      } else {
        this.onDeleteTourpaxgroup(tpax);
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close' && result['reason'] !== 'delete') {
        tp = result.tourpax;
        tp.user = result.tourpax.user;
        tp.room = result.tourpax.room;
        tp.active = result.tourpax.active;
        tp.hidden = result.tourpax.hidden;
        const tpidx = this.tourPax.findIndex(t => +t.id === +tp.id);
        this.tourPax[tpidx] = tp;
        this.processLists();
      } else if (result && result['reason'] === 'delete') {
        const tpIdx = this.tourPax.findIndex(tpax => +tp.id === +tpax.id);
        this.tourPax.splice(tpIdx, 1);
        this.processLists();
      } else {
        this.updateSummary();
      }
    });
  }

  getBackgroundColor(tpax: TourPax): string {
    return tpax?.tourpax_group?.tour_group?.color ? tpax.tourpax_group.tour_group.color + '33' : null;
  }

  // Remove from tour
  onRemove(tpax: TourPax) {
    this.loadingPAX = true;
    this.httpTour.deleteTourPax(tpax.id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const tpIdx = this.tourPax.findIndex(tp => +tp.id === +tpax.id);
            if (tpIdx >= 0) {
              this.tourPax.splice(tpIdx, 1);
            }
            const groupExists = this.activeTour.groups.filter(group => group.id === tpax.gl_tg?.id);
            if (groupExists.length > 0) {
              this.activeTour.groups = this.activeTour.groups.filter(group => group.id !== tpax.gl_tg?.id);
              this.activeTourSvc.setActiveTour(this.activeTour);
              this.tourPaxActive.map(tp_active => {
                if (tp_active.tourpax_group?.tour_group?.id === tpax.gl_tg?.id) {
                  tp_active.tourpax_group = null;
                }
              });
              this.tourPaxFake.map(tp_fake => {
                if (tp_fake.tourpax_group?.tour_group?.id === tpax.gl_tg?.id) {
                  tp_fake.tourpax_group = null;
                }
              });
            }
            this.processLists();
            this.loadingPAX = false;
          } else {
            this.loadingPAX = false;
          }
        },
        error => {
          this.loadingPAX = false;
          console.log(error);
        });
  }

  onMergeTP(tp_fake: TourPax, tp_active: TourPax) {
    this.mergeTourpax(tp_active, tp_fake);
  }

  mergeTourpax(tp_active, tp_fake) {
    this.loadingPAX = true;
    const data = {
      'tp_active': tp_active.id,
      'tp_fake': tp_fake.id,
    };
    // console.log(data);
    this.httpTour.mergeTwoTourPax(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.tourPax = res.results.tour_paxs;
            this.activeTour.groups = res.results.tour_groups;
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.processLists();
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingPAX = false;
        },
        error => {
          this.loadingPAX = false;
          console.log(error);
        });
  }

  // Tour group

  onAddToGL(tp, tg) {
    this.onCreateTourpaxgroup(tp, tg);
  }

  async onCreateTourpaxgroup(tp: TourPax, tg: TourGroup) {
    this.loadingPAX = true;
    const data = {
      'tour_group_id': tg.id,
      'tour_pax_id': tp.id,
    };
    // console.log(data);
    const res = await this.httpTour.createTourPaxGroup(data);
    console.log(res);
    if (res.status < 400) {
      tp.tourpax_group = res.results.tourpax_group;
      this.loadingPAX = false;
    } else {
      this.loadingPAX = false;
      this.snackSvc.resultsElse(res);
    }
  }

  onRemoveTPG(tp) {
    this.onDeleteTourpaxgroup(tp);
  }

  async onUpdateTourpaxgroup(tp: TourPax) {
    this.loadingPAX = true;
    const data = {
      'tour_pax_id': tp.id,
      'tour_group_id': tp.tourpax_group.tour_group_id,
    };
    // console.log(data);
    const res = await this.httpTour.updateTourPaxGroup(tp.tourpax_group.id, data);
    console.log(res);
    if (res.status < 400) {
      this.processLists();
      this.loadingPAX = false;
    } else {
      this.loadingPAX = false;
      this.snackSvc.resultsElse(res);
    }
  }

  async onDeleteTourpaxgroup(tp: TourPax) {
    this.loadingPAX = true;
    const res = await this.httpTour.deleteTourPaxGroup(tp.tourpax_group.id);
    console.log(res);
    if (res.status < 400) {
      tp.tourpax_group = null;
      this.loadingPAX = false;
    } else {
      this.loadingPAX = false;
      this.snackSvc.resultsElse(res);
    }
  }

  // Other

  onRefreshTP() {
    this.getTourPax(this.activeTour.tour.id);
  }

  notify(txt?) {
    if (txt === '1') {
      this.ok_copied1 = true;
      setTimeout(() => {
        this.ok_copied1 = false;
      }, 2000);
    } else {
      this.ok_copied2 = true;
      this.snackSvc.openSnackBar('Instructions copied');
      setTimeout(() => {
        this.ok_copied2 = false;
      }, 2000);
    }
  }

  goBack() {
    if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
