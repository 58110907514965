import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Company} from '../../../../../../shared/models/company.model';
import {User} from '../../../../../../shared/models/user.model';
import {environment} from '../../../../../../../environments/environment';
import {Activetour} from '../../../../../../shared/models/activetour.model';
import {ActivetourService} from '../../../../../../shared/services/session/activetour.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../../../../shared/services/session/authentication.service';
import {NewtourService} from '../../services/newtour.service';
import {Nrooms} from '../../../../../../shared/models/nrooms.model';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit, OnDestroy {
  // Active tour info
  activeTour: Activetour;
  currentUser: User;

  companies: Company[];
  baseUrlLogo: string;
  loading = false;

  @Output() updateCia = new EventEmitter();
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private activeTourSvc: ActivetourService,
    private newTourSvc: NewtourService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
  }

  ngOnInit(): void {
    this.baseUrlLogo = environment.baseUrl;
    this.companies = JSON.parse(localStorage.getItem('companies') || '[]');
    if (this.currentUser.role < User.admin) {
      this.companies = this.companies.filter(it => it.id !== 1);
    }
  }

  onSelectionCiaChange(event) {
    this.activeTour.tour.company_id = event.value;
    this.activeTour.tour.company = this.companies.find(c => '' + c.id === '' + event.value);
    this.activeTour.tour.logo = this.activeTour.tour.company.logo;
    this.activeTourSvc.setActiveTour(this.activeTour);
    this.updateCia.emit();
  }

  removeCia() {
    this.activeTour.tour.company_id = null;
    this.activeTour.tour.company = null;
    this.activeTour.tour.logo = null;
    this.activeTour.tour.npax = null;
    this.activeTour.tour.rms = new Nrooms();
    this.activeTourSvc.setActiveTour(this.activeTour);
    this.newTourSvc.setManually(false);
    this.updateCia.emit();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
